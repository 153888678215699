import styled, { css } from "styled-components";

export const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize || 16}px;
  font-weight: ${({ fontWeight }) => fontWeight || 300};
  color: ${({ color, colors }) => colors[color] || colors.secondary};
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
  
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft}px;
    `}
  
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}
  
  ${({ textShadowColor, colors }) =>
    textShadowColor &&
    css`
      -webkit-text-stroke: 2px ${colors[textShadowColor] || textShadowColor};
    `}
  
  ${({ hasClick }) =>
    hasClick &&
    css`
      cursor: pointer;
    `}

  ${({ limitText }) =>
    limitText &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
  
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;
