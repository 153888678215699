import styled from 'styled-components';
import { colors } from "../../styles"

export const Container = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.mercury2};
  border-radius: 5px;
  width: 90%;
  max-width: 350px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  & > svg {
    height: 15px;
    width: 15px;
    margin-right: 15px;
  }
`;

export const Input = styled.input`
  border: none;
  font-size: 16px;
  height: 100%;
  width: 100%;
`;