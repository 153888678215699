import { createGlobalStyle } from "styled-components";
import { colors, fonts } from "./index";

import "./fonts/Gilroy.css";
import "rc-slider/assets/index.css";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    body {
        font-family: ${fonts.primary}, sans-serif;
        font-size: 16px;
        background: ${colors.white};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        color: ${colors.secondary};
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    html, body, #root {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
`;
