import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Creators as PlaylistsActions } from "../../store/ducks/playlists";
import { Creators as PlaylistDetailsActions } from "../../store/ducks/playlistDetails";

import { getNumberOfAlbumsLabel, autocomplete } from "../../utils";

import { routes } from "../../routes";

import Layout from "../../components/Layout";
import GenericList from "../../components/GenericList";
import Badge from "../../components/Badge";
import Text from "../../components/Text";
import SearchInput from "../../components/SearchInput";
import { LibrarySVG } from "../../assets";

import * as s from "./styles";

function Library() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setFilter] = useState(0);
  const { playlists, loading } = useSelector((state) => state.playlists);

  const filters = loading
    ? []
    : ["Todos", ...[...new Set(playlists
      .map((p) => {
        if (!p.active) {
          return null
        }

        return p.tag
      }).filter(p => p))]];

  const itemsFiltered =
    activeFilter > 0
      ? playlists.filter((p) => p.tag === filters[activeFilter])
      : playlists;

  const items = searchTerm
    ? autocomplete(itemsFiltered, searchTerm, ["name"])
    : itemsFiltered;

  let history = useHistory();

  async function goToAlbum(p) {
    await dispatch(PlaylistDetailsActions.setPlaylistDetails(p));
    history.push(routes.album);
  }

  useEffect(() => {
    dispatch(PlaylistsActions.getPlaylistsRequest());
  }, []);

  useEffect(() => {
    if (history?.location?.search.includes('tag')) {
      const [_, splittedSearch] = history?.location?.search.split('?tag=')
      setFilter(splittedSearch);
    }
  }, [history])

  return (
    <Layout pageTitle="Biblioteca">
      <s.Container>
        <SearchInput
          value={searchTerm}
          onChange={({ target }) => setSearchTerm(target.value)}
        />
        <s.Filters>
          {filters.map((f) => (
            <Badge
              key={f}
              onClick={() => {
                setFilter(filters.indexOf(f))
                history.push({
                  search: `tag=${filters.indexOf(f)}`
                })
              }}
              active={filters[activeFilter] === f}
              size="medium"
            >
              {f}
            </Badge>
          ))}
        </s.Filters>
        {!items.length && !loading && (
          <s.EmptyList>
            <LibrarySVG color="silver" />
            <Text fontSize="18">Nenhum item encontrado :(</Text>
          </s.EmptyList>
        )}
        {(items.length || loading) && (
          <GenericList
            onClick={goToAlbum}
            title={filters[activeFilter]}
            loading={loading}
            headerRight={
              <Text fontSize="16" fontWeight="300">
                {getNumberOfAlbumsLabel(items)}
              </Text>
            }
            hasBadge
            withSkeleton
            items={items}
          />
        )}
      </s.Container>
    </Layout>
  );
}

export default Library;
