import styled, { css } from "styled-components";
import { darken } from "polished";
import { colors } from "../../styles";

const sizes = {
  xsmall: css`
    font-size: 10px;
    height: 20px;
    padding: 0 5px;
    border-radius: 5px;
  `,
  small: css`
    font-size: 12px;
    height: 25px;
    padding: 0 10px;
    border-radius: 10px;
  `,
  medium: css`
    font-size: 14px;
    height: 30px;
    padding: 0 15px;
    border-radius: 100px;
  `,
};

export const Badge = styled.span`
  background: ${({ background, active }) => {
    const color = colors[background] || background || colors.primary;
    if (active) {
      return darken(0.20, color);
    }
    return color;
  }};
  color: ${colors.white};
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
  white-space: nowrap;

  ${({ size }) => sizes[size] || sizes.small}
`;
