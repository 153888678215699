import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations2";
import { listBookmarks, myList } from "../../graphql/queries2";
import { apiGetPlaysOfUser } from "./play";

export async function apiSetBookmark(input) {
  try {
    const operation = input.id ? "updateBookmark" : "createBookmark";
    const { data } = await API.graphql(
      graphqlOperation(mutations[operation], { input })
    );
    return data[operation];
  } catch (error) {
    console.log("error in set bookmark >> ", error);
    return null;
  }
}

export async function apiGetBookmarksOfUser(userid, playlistID, onlyActive) {
  try {
    const { data } = await API.graphql(
      graphqlOperation(listBookmarks, {
        filter: {
          userid: {
            eq: userid,
          },
          ...(playlistID && {
            playlistid: {
              eq: playlistID,
            },
          }),
          ...(onlyActive && {
            active: {
              eq: true,
            },
          }),
        },
      })
    );
    return data.listBookmarks.items;
  } catch (error) {
    console.log("error in list bookmark >> ", error);
    return null;
  }
}

export async function apiGetMyList(userid) {
  try {
    const { data } = await API.graphql(
      graphqlOperation(myList, {
        filter: {
          userid: {
            eq: userid,
          },
          active: {
            eq: true,
          },
        },
      })
    );

    const plays = await apiGetPlaysOfUser(userid);

    const list = data.listBookmarks.items.map((b) => {
      const { id: playID, timePlay, completed } =
        plays.find(
          (p) => p.fileid === b.file.id && p.playlistid === b.playlist.id
        ) || {};

      return {
        ...b.file,
        bookmarkID: b.id,
        bookmarked: b.active,
        album: b.playlist.name,
        thumbnail: b.playlist.coverUrl,
        playlistID: b.playlist.id,
        desc: b.playlist.desc,
        playID,
        timePlay,
        completed
      };
    });

    return list;
  } catch (error) {
    console.log("error in get my list >> ", error);
    return null;
  }
}
