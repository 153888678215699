import React, { useCallback, useEffect, useState } from "react";
import Sound from "react-sound";
import { useSelector, useDispatch } from "react-redux";
import { Storage } from "aws-amplify";

import { Creators as PlayerActions } from "../../store/ducks/player";
import { msToTime, getProgress, isMagaluUrl } from "../../utils";
import { apiSetPlay } from '../../services/amplify/play';

import MiniPlayer from "./MiniPlayer";
import FullPlayer from "./FullPlayer";
import LivePlayer from "./LivePlayer";

import ProgressSlider from "./ProgressSlider";
import ControlButton from "./ControlButtons";

export { ProgressSlider, ControlButton };

export default function () {
  const [currentSongData, setCurrentSongData] = useState({});
  const [counter, setCounter] = useState(0);
  const [stopedLive, setStopedLive] = useState(false);

  const { incrementMiliSeconds } = useSelector((state) => state.config);

  const player = useSelector((state) => state.player);
  const isLive = useSelector((state) => state.player.isLive);
  const userId = useSelector((state) => state.auth.attributes.id);

  const position = msToTime(player.position);
  const duration = msToTime(player.duration);
  const progress = getProgress(player);

  const timeLeft = msToTime(player.duration - player.position);

  const dispatch = useDispatch();

  const { id: songId } = player.currentSong || {};

  const fetchPlayLive = useCallback(async () => {
    const input = {
      userid: userId,
      fileid: 'radioaovivo',
      playlistid: 'radioaovivo',
      timePlay: counter,
      completed: false,
    };

    await apiSetPlay(input).then(() => {
      setCounter(0);
      setStopedLive(true);
    });
  }, [counter, userId]);


  async function getUrl() {
    const urlS3 = await Storage.get(
      player.currentSong.key.replace("public/", ""),
      {
        level: "public",
      }
    );
    //TODO: Guardar a url no redux
    setCurrentSongData({ ...player.currentSong, urlS3 });
  }

  useEffect(() => {
    let intervalId
    if (player.isLive) {
      intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter + 1);
      }, 10);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [player.isLive]);

  useEffect(() => {
    if (counter > 0 && !player.isLive && player.type !== 'LIVE' && !stopedLive) {
      fetchPlayLive();
    }
  }, [counter, fetchPlayLive, stopedLive, player.isLive, player.type]);

  useEffect(() => {
    if (songId && songId !== currentSongData.id) {
      getUrl();
    }
  }, [songId]);

  useEffect(() => {
    if (stopedLive) {
      setCounter(0);
    }
  }, [stopedLive])

  if ((!player.currentSong && !currentSongData.urlS3) && player.type !== 'LIVE' && !player.isLive) {
    return null;
  }

  const properties = {
    player,
    position,
    duration,
    progress,
    timeLeft,
    incrementMiliSeconds,
    incrementSeconds: incrementMiliSeconds / 1000,
  };

  const liveAudioProperties = {
    title: 'Rádio Luiza',
    description: 'Ao vivo',
    status: isLive ? 'PLAYING' : null
  }

  return (
    <>
      {!!player.currentSong && (
        <Sound
          url={currentSongData.urlS3}
          playStatus={player.status}
          onFinishedPlaying={(e) =>
            dispatch(
              PlayerActions.next({
                currentSong: player.currentSong,
                currentSongFinished: true,
              })
            )
          }
          onPlaying={({ position, duration }) =>
            duration &&
            dispatch(
              PlayerActions.playing({
                currentSong: player.currentSong,
                position,
                duration,
              })
            )
          }
          position={player.position}
          volume={player.volume}
        />
      )}
      {player.type === "MINI" && <MiniPlayer properties={properties} />}
      {player.isLive && isMagaluUrl() ? <LivePlayer setStopedLive={setStopedLive} properties={liveAudioProperties} /> : null}
      {player.type === "FULL" && <FullPlayer properties={properties} />}
    </>
  );
}
