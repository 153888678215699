import styled, { css } from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${colors.white};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;

  & > :not(:last-child) {
    margin-right: 15px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 55vh;
  max-height: 55vh;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  & svg {
    cursor: pointer;
  }

  & > * {
    padding-top: 15px;
  }

  & > :not(:last-child) {
    border-bottom: 1px solid ${colors.alto};
    padding-bottom: 15px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 360px;
  width: 100%;
  align-items: center;

  & > span {
    flex: 1;
  }
`;