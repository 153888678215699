import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Creators as AuthActions } from "../../store/ducks/auth";

import Splash from "../../components/Splash";

import { routes } from "../../routes";

function Redirect() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const { isLoading } = useSelector((state) => state.auth.signInState);

  async function setUp() {
    const { email } = params || {};
    await dispatch(AuthActions.setSignInState({ emailReceived: email }));
    history.push(routes.login);
  }

  useEffect(() => {
    setUp();
  }, []);

  if (isLoading) {
    return <Splash />;
  }

  return <></>;
}

export default Redirect;
