import { Auth, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations2";
import { getUser } from "../../graphql/queries2";
import * as queries from "../../graphql/queries";

export async function getPublicCompaniesConfigs(mailDomain) {
  try {
    // change to getByDomain
    const {
      data: { listCompanyConfigs },
    } = await API.graphql({
      query: queries.listCompanyConfigs,
      variables: {
        filter: {
          mailDomain: {
            eq: mailDomain,
          },
        },
      },
      authMode: "API_KEY",
    });

    console.log(listCompanyConfigs, 'data', mailDomain);

    const { items = [] } = listCompanyConfigs;

    return items[0];
  } catch (error) {
    console.log("error in getPublicCompaniesConfigs :>> ", error);
    return null;
  }
}

export async function getCurrentSession() {
  try {
    const session = await Auth.currentSession();

    return session;
  } catch (error) {
    return null;
  }
}

export async function getCurrentUser() {
  try {
    const user = await Auth.currentAuthenticatedUser();

    return user;
  } catch (error) {
    return null;
  }
}

export async function getUserAttributes() {
  try {
    const attributes = await Auth.getUserAttributes();

    return attributes;
  } catch (error) {
    return null;
  }
}

export async function getCognitoUser() {
  const [Session, cognitoUser] = await Promise.all([
    getCurrentSession(),
    getCurrentUser(),
  ]);

  if (!Session || !cognitoUser) {
    return null;
  }

  Object.assign(cognitoUser, { Session });

  return cognitoUser;
}

function parseUser(shastaUser) {
  const { cognitoGroup } = shastaUser;
  if (!cognitoGroup) {
    return null;
  }

  const { company } = cognitoGroup;
  const { companyConfigs } = company;

  const { logoUrl, coverUrl, ...companyConfig } = companyConfigs.items[0];

  Object.assign(company, { logoUrl, coverUrl });

  Object.assign(shastaUser, { cognitoGroupName: cognitoGroup.name });

  return { shastaUser, company, companyConfig };
}

export async function apiGetUser(id) {
  try {
    const { data } = await API.graphql({
      query: getUser,
      variables: { id },
      authMode: "API_KEY",
    });
    const shastaUser = data.getUser || {};
    return parseUser(shastaUser);
  } catch (error) {
    console.log("error in apiGetUser >> ", error);
    throw error;
  }
}

export async function apiGetUserAuth(email) {
  try {
    const { data } = await API.graphql({
      query: queries.listUserAuths,
      variables: {
        filter: {
          emailLogin: {
            eq: email,
          },
        },
      },
      authMode: "API_KEY",
    });
    const { listUserAuths: { items } } = data || {};
    return items && items.length ? items[0] : null;
  } catch (error) {
    console.log("error in apiGetUserAuth >> ", error);
    throw error;
  }
}

export async function apiSetUser(input) {
  try {
    const { shastaUser = {} } = (await apiGetUser(input.id)) || {};
    const operation =
      !shastaUser || !shastaUser.id ? "createUser" : "updateUser";

    if (!shastaUser || !shastaUser.id) {
      const {
        data: {
          listCognitoGroups: { items },
        },
      } = await API.graphql({
        query: queries.listCognitoGroups,
        variables: {
          filter: {
            cognitoPoolID: {
              eq: input.cognitoPoolID,
            },
          },
        },
        authMode: "API_KEY",
      });

      if (items && items.length) {
        const { companyID, cognitoPoolID, id: cognitoGroupID } = items[0] || {};
        Object.assign(input, { companyID, cognitoPoolID, cognitoGroupID });
      }
    }
    const { data: newUser } = await API.graphql({
      query: mutations[operation],
      variables: { input },
      authMode: "API_KEY",
    });

    Object.assign(shastaUser, newUser[operation]);

    return parseUser(shastaUser);
  } catch (error) {
    console.log("error in apiSetUser >> ", error);
    throw error;
  }
}
