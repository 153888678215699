import React from "react";
import { useSelector } from "react-redux";

import * as s from "./styles";

function Button({ children, ...props }) {
  const { colors } = useSelector((state) => state.config);
  return <s.Button colors={colors} {...props}>{children}</s.Button>;
}

export default Button;
