import React from "react";
import { useSelector } from "react-redux"

// import { Container } from './styles';

function ArrowDownSVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11.625L0.625 2.25L1.9375 0.9375L10 9L18.0625 0.9375L19.375 2.25L10 11.625Z"
        fill={fill}
      />
    </svg>
  );
}

export default ArrowDownSVG;
