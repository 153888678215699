import { all, takeLatest, throttle } from "redux-saga/effects";

import { Types as PlaylistsTypes } from "../ducks/playlists";
import { Types as PlaylistDetailsTypes } from "../ducks/playlistDetails";
import { Types as PlayerTypes } from "../ducks/player";
import { Types as ProfileTypes } from "../ducks/profile";
import { Types as ConfigTypes } from "../ducks/config";
import { Types as AuthTypes } from "../ducks/auth";

import { getPlaylists, getMyList } from "./playlists";
import { getDetails } from "./playlistDetails";
import { setPlay, setLive } from "./player";
import { setBookmark } from "./bookmark";
import { getMetrics } from "./profile";
import { signIn, setUp, checkFederatedSignIn } from "./auth";

export default function* rootSaga() {
  yield all([
    /**
     * Playlists
     */
    takeLatest(PlaylistsTypes.GET_REQUEST, getPlaylists),
    takeLatest(PlaylistsTypes.GET_MY_LIST_REQUEST, getMyList),

    /**
     * PlaylistDetails
     */
    takeLatest(PlaylistDetailsTypes.GET_AUDIOS_REQUEST, getDetails),

    /**
     * Player
     */
    throttle(
      1000,
      [
        PlayerTypes.LOAD,
        PlayerTypes.PLAY,
        PlayerTypes.PAUSE,
        // PlayerTypes.HANDLE_POSITION,
        PlayerTypes.SET_POSITION,
        PlayerTypes.PLAYING,
      ],
      setPlay
    ),
    // throttle(2000, PlayerTypes.PLAYING, setPlay),

    // takeLatest(PlayerTypes.NEXT, setPlay),
    // takeLatest(PlayerTypes.PREV, setPlay),

    /**
     * Bookmark
     */
    takeLatest(PlayerTypes.SET_BOOKMARK, setBookmark),

    /**
     * Profile
     */
    takeLatest(ProfileTypes.GET_METRICS_REQUEST, getMetrics),

    /**
     * Auth
     */
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SET_UP_REQUEST, setUp),
    takeLatest(AuthTypes.CHECK_FEDERATED_SIGNIN, checkFederatedSignIn),

    takeLatest(PlayerTypes.LIVE, setLive)
  ]);
}
