import styled, { css, keyframes } from "styled-components";
import { colors, animations } from "../../styles";

export const Overlay = styled.div`
  display: ${({ isOverlayOpen }) => (isOverlayOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  height: 75vh;
  max-height: 450px;
  width: 100%;
  background: ${colors.gallery};
  border-top-left-radius: ${({ borderRadius }) => borderRadius || "50px"};
  border-top-right-radius: ${({ borderRadius }) => borderRadius || "50px"};
  z-index: 10;
  position: relative;
  padding: 35px 15px 35px;
  align-items: center;

  animation: ${({ isOpen }) =>
    isOpen
      ? css`0.2s ${animations.slideInUpAnimation}`
      : css`0.2s ${animations.slideOutDownAnimation} forwards`};
`;
