import styled from "styled-components";
import { device } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
`;

export const Filters = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100vw;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;

  @media ${device.fromMobileL} {
    max-width: 100%;
  }

  &:-webkit-scrollbar {
    display: none;
  }

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 25px;

  & > svg {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }
`;