import React from "react";
import { useSelector } from "react-redux";

export function PlaySVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9167 17.9378L10.7931 25.5525C9.67931 26.1981 8.25 25.4164 8.25 24.115V8.88548C8.25 7.5861 9.67725 6.80235 10.7931 7.44998L23.9167 15.0647C24.1701 15.2094 24.3807 15.4184 24.5272 15.6707C24.6737 15.923 24.7508 16.2095 24.7508 16.5013C24.7508 16.793 24.6737 17.0795 24.5272 17.3318C24.3807 17.5841 24.1701 17.7932 23.9167 17.9378Z"
        fill={fill}
      />
    </svg>
  );
}

export function PauseSVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6H10C9.46957 6 8.96086 6.21071 8.58579 6.58579C8.21071 6.96086 8 7.46957 8 8V24C8 24.5304 8.21071 25.0391 8.58579 25.4142C8.96086 25.7893 9.46957 26 10 26H12C12.5304 26 13.0391 25.7893 13.4142 25.4142C13.7893 25.0391 14 24.5304 14 24V8C14 7.46957 13.7893 6.96086 13.4142 6.58579C13.0391 6.21071 12.5304 6 12 6Z"
        fill={fill}
      />
      <path
        d="M22 6H20C19.4696 6 18.9609 6.21071 18.5858 6.58579C18.2107 6.96086 18 7.46957 18 8V24C18 24.5304 18.2107 25.0391 18.5858 25.4142C18.9609 25.7893 19.4696 26 20 26H22C22.5304 26 23.0391 25.7893 23.4142 25.4142C23.7893 25.0391 24 24.5304 24 24V8C24 7.46957 23.7893 6.96086 23.4142 6.58579C23.0391 6.21071 22.5304 6 22 6Z"
        fill={fill}
      />
    </svg>
  );
}

export function PrevSVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28769 13.484L23.414 23.6294C24.6152 24.4146 25.6001 23.8385 25.6001 22.3482L25.6001 2.64973C25.6001 1.16348 24.6152 0.587435 23.414 1.36848L8.28769 11.518C8.28769 11.518 7.7018 11.926 7.7018 12.4979C7.7018 13.0719 8.28769 13.484 8.28769 13.484ZM4.6001 24.8H2.5001C1.3388 24.8 0.400097 24.7016 0.400097 23.57L0.400097 1.42999C0.400097 0.298386 1.3388 0.199986 2.5001 0.199986H4.6001C5.7614 0.199986 6.7001 0.298386 6.7001 1.42999L6.7001 23.57C6.7001 24.7016 5.7614 24.8 4.6001 24.8Z"
        fill={fill}
      />
    </svg>
  );
}

export function NextSVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7123 11.516L2.586 1.37056C1.3848 0.585412 0.399902 1.16146 0.399902 2.65181V22.3503C0.399902 23.8365 1.3848 24.4126 2.586 23.6315L17.7123 13.482C17.7123 13.482 18.2982 13.074 18.2982 12.5021C18.2982 11.9281 17.7123 11.516 17.7123 11.516ZM21.3999 0.200012H23.4999C24.6612 0.200012 25.5999 0.298412 25.5999 1.43001V23.57C25.5999 24.7016 24.6612 24.8 23.4999 24.8H21.3999C20.2386 24.8 19.2999 24.7016 19.2999 23.57V1.43001C19.2999 0.298412 20.2386 0.200012 21.3999 0.200012Z"
        fill={fill}
      />
    </svg>
  );
}

export function IncrementSVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 29 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13866 28.4096C10.7824 29.0292 12.4757 29.3019 14.2186 29.2277C15.9614 29.1536 17.5782 28.7792 19.0689 28.1046C20.5597 27.4299 21.9081 26.4625 23.1142 25.2022C24.3203 23.942 25.2331 22.49 25.8527 20.8462C26.5676 18.9495 26.8229 16.9789 26.6188 14.9344L23.3829 15.2581C23.5425 16.7984 23.3542 18.2798 22.818 19.7024C22.1984 21.3461 21.218 22.714 19.8768 23.806C18.5356 24.898 17.0122 25.5874 15.3066 25.8742C13.601 26.1609 11.9263 25.9945 10.2825 25.375C8.63869 24.7554 7.27079 23.775 6.1788 22.4338C5.0868 21.0926 4.39743 19.5691 4.11066 17.8635C3.8239 16.1579 3.9903 14.4832 4.60987 12.8394C5.33667 10.9112 6.54123 9.37955 8.22355 8.24463C9.90587 7.10972 11.7385 6.5459 13.7213 6.55318L13.7268 3.30604C11.0757 3.29961 8.62893 4.05614 6.38649 5.57563C4.14405 7.09511 2.54028 9.13511 1.57519 11.6956C0.955618 13.3394 0.682922 15.0327 0.757099 16.7755C0.831277 18.5183 1.20567 20.1351 1.88027 21.6259C2.55488 23.1167 3.52232 24.4651 4.78258 25.6712C6.04284 26.8772 7.49487 27.7901 9.13866 28.4096Z"
        fill={fill}
      />
      <path
        d="M18.3286 4.82855L13.7134 0.213315L13.7134 9.44379L18.3286 4.82855Z"
        fill={fill}
      />
    </svg>
  );
}

export function DecrementSVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8613 28.4096C20.2176 29.0292 18.5243 29.3019 16.7814 29.2277C15.0386 29.1536 13.4218 28.7792 11.9311 28.1046C10.4403 27.4299 9.09188 26.4625 7.8858 25.2022C6.67972 23.942 5.76689 22.49 5.14733 20.8462C4.43244 18.9495 4.17707 16.9789 4.38122 14.9344L7.61705 15.2581C7.45753 16.7984 7.64585 18.2798 8.18201 19.7024C8.80158 21.3461 9.78198 22.714 11.1232 23.806C12.4644 24.898 13.9878 25.5874 15.6934 25.8742C17.399 26.1609 19.0737 25.9945 20.7175 25.375C22.3613 24.7554 23.7292 23.775 24.8212 22.4338C25.9132 21.0926 26.6026 19.5691 26.8893 17.8635C27.1761 16.1579 27.0097 14.4832 26.3901 12.8394C25.6633 10.9112 24.4588 9.37955 22.7765 8.24463C21.0941 7.10972 19.2615 6.5459 17.2787 6.55318L17.2732 3.30604C19.9243 3.29961 22.3711 4.05614 24.6135 5.57562C26.8559 7.09511 28.4597 9.13511 29.4248 11.6956C30.0444 13.3394 30.3171 15.0327 30.2429 16.7755C30.1687 18.5183 29.7943 20.1351 29.1197 21.6259C28.4451 23.1167 27.4777 24.4651 26.2174 25.6712C24.9572 26.8772 23.5051 27.7901 21.8613 28.4096Z"
        fill={fill}
      />
      <path
        d="M12.6714 4.82855L17.2866 0.213315L17.2866 9.44379L12.6714 4.82855Z"
        fill={fill}
      />
    </svg>
  );
}
