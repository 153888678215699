import styled, { css } from "styled-components";
import { fonts } from "../../styles";

const buttonSize = {
  default: css`
    width: 20px;
    height: 20px;
  `,
  medium: css`
    width: 25px;
    height: 25px;
  `,
  large: css`
    width: 30px;
    height: 30px;
  `,
  xlarge: css`
    width: 40px;
    height: 40px;
  `,
  xxlarge: css`
    width: 65px;
    height: 65px;
  `,
  small: css`
    width: 15px;
    height: 15px;
  `,
};

export const ControlButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ size }) => buttonSize[size || "default"]}

  ${({ withBorder, color, colors }) =>
    withBorder &&
    css`
      border: 1px solid ${colors[color] || color};
      border-radius: 100%;
    `}

  & svg {
    ${({ size }) => buttonSize[size || "default"]}
  }
`;

export const TextCentered = styled.span`
  font-weight: 800;
  font-size: 13px;
  position: absolute;
  font-family: ${fonts.primary};
  color: ${({ colors }) => colors.primary};
`;

export const ProgressSlider = styled.div`
  width: 100%;
  max-width: 500px;
`;
