export const Types = {
  GET_METRICS_REQUEST: "profile/GET_METRICS_REQUEST",
  GET_METRICS_SUCCESS: "profile/GET_METRICS_SUCCESS",
};

const INITIAL_STATE = {
  plays: [],
  bookmarks: [],
  loading: true,
};

export default function playlistDetails(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_METRICS_REQUEST:
      console.log('profile ducks >> playlistDetails >> GET_METRICS_REQUEST', state);
      return {
        ...state,
        loading: !state?.plays?.length && !state?.bookmarks?.length,
      };
    case Types.GET_METRICS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    default:
      return state;
  }
}

export const Creators = {
  getMetricsRequest: (payload) => ({
    type: Types.GET_METRICS_REQUEST,
    payload,
  }),
  getMetricsSuccess: (payload) => ({
    type: Types.GET_METRICS_SUCCESS,
    payload,
  }),
};
