import React, { useRef } from "react";
import Slider from "rc-slider";
import { useSelector, useDispatch } from "react-redux";
import { Creators as PlayerActions } from "../../../store/ducks/player";
import { colors } from "../../../styles";

import Text from "../../Text";

import * as s from "./styles";

import ControlButton from "../ControlButtons";

export default function MiniPlayer({ properties }) {
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const { coverUrl } = useSelector(state => state.auth.company);

  const { player, progress } = properties;

  if (!player.currentSong) {
    return null;
  }

  function expandPlayer(event) {
    if (
      buttonRef &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      dispatch(PlayerActions.setType("FULL"));
    }
  }

  return (
    <s.Container onClick={expandPlayer}>
      <s.ProgressSliderTop>
        <Slider
          railStyle={{ background: "transparent", borderRadius: 10 }}
          trackStyle={{ background: colors.primary }}
          handleStyle={{ display: "none" }}
          max={1000}
          onChange={(value) =>
            dispatch(
              PlayerActions.handlePosition({
                percentage: value / 1000,
                currentSong: player.currentSong,
              })
            )
          }
          onAfterChange={(value) =>
            dispatch(
              PlayerActions.setPosition({
                percentage: value / 1000,
                currentSong: player.currentSong,
              })
            )
          }
          value={progress}
        />
      </s.ProgressSliderTop>
      <s.Content>
        <s.Current>
          <img src={player.currentSong.thumbnail || coverUrl} alt="" />
          <div>
            <Text
              marginBottom="5"
              fontSize="16"
              fontWeight={800}
              color="primary"
            >
              {player.currentSong.album}
            </Text>
            <Text limitText width="190px" fontSize="14" fontWeight={300}>
              <b>#{player.currentSong.index}</b> {player.currentSong.title}
            </Text>
          </div>
        </s.Current>
        <div ref={buttonRef}>
          <ControlButton
            type={
              player.currentSong && player.status === "PLAYING"
                ? "pause"
                : "play"
            }
          />
        </div>
      </s.Content>
    </s.Container>
  );
}
