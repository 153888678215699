import { colors } from "../../styles";

export const Types = {
  SET_CONFIG: "config/SET_CONFIG",
  SET_COLORS: "config/SET_COLORS",
};

const INITIAL_STATE = {
  incrementMiliSeconds: 15000,
  audioCompletedParam: 85,
  colors,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    case Types.SET_COLORS:
      return {
        ...state,
        colors: { ...state.colors, ...action.payload },
      };
    default:
      return state;
  }
}

export const Creators = {
  setConfig: (payload) => ({ type: Types.SET_CONFIG, payload }),
  setColor: (payload) => ({ type: Types.SET_COLORS, payload }),
};
