const defaultColors = {
  primary: "#FFB200",
  secondary: "#2D2D2D",
  shastaPrimary: "#FFB200",
  shastaSecondary: "#2D2D2D",
  danger: "#FE483E",
  white: "#FFFFFF",
  mineShaft: "#1F1F1F",
  gallery: "#EEEEEE",
  dustyGray: "#9A9A9A",
  alabaster: "#FBFBFB",
  mercury: "#E8E8E8",
  alto: "#D8D8D8",
  mercury2: '#E4E4E4',
  mercury3: "#E3E3E3",
  silver: "#C4C4C4",
  transparent: "transparent",
};

export default {
  ...defaultColors,
  primaryGradient: `linear-gradient(
    180deg,
    ${defaultColors.secondary} 0%,
    ${defaultColors.mineShaft} 100%
  ),
  transparent`,
};
