import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Creators as PlaylistsActions } from "../../store/ducks/playlists";

import { autocomplete } from "../../utils"

import Layout from "../../components/Layout";
import SearchInput from "../../components/SearchInput";
import AudioListMisc from "../../components/AudioListMisc";

import * as s from "./styles";

function MyList() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const { myList, loading } = useSelector(state => state.playlists);

  const items = searchTerm ? autocomplete(myList, searchTerm, ["title", "album"]) : myList;

  useEffect(() => {
    dispatch(PlaylistsActions.getMyListsRequest());
  }, []);

  return (
    <Layout pageTitle="Minha lista">
      <s.Container>
        <SearchInput value={searchTerm} onChange={({ target }) => setSearchTerm(target.value)} />
        <AudioListMisc withSkeleton audios={items} loading={loading} />
      </s.Container>
    </Layout>
  );
}

export default MyList;
