import React from "react";
import { useDispatch, useSelector } from "react-redux";


import Text from "../Text";
import AudioInfo from "../AudioInfo";
import { ProgressSlider, ControlButton } from "../Player";
import { Creators as PlayerActions } from "../../store/ducks/player";

import * as s from "./styles";

export function KeepListening({ playlist }) {
  const { colors } = useSelector((state) => state.config);

  return (
    <s.Container>
      <s.Content>
        <Text fontSize="24" fontWeight={300} marginBottom={5}>
          <b>Continue</b> ouvindo
        </Text>
        <s.Card height="auto">
          <AudioInfo playlist={playlist}>
            <ProgressSlider railColor={colors.mercury} trackColor={colors.primary} />
          </AudioInfo>
          <ControlButton type="play" withBorder color="primary" size="xlarge" />
        </s.Card>
      </s.Content>
    </s.Container>
  );
}

export function Featured({ playlist, onClick, loading }) {
  return (
    <s.Container>
      <s.Content onClick={() => onClick(playlist)}>
        <Text fontSize="20" fontWeight={800} marginBottom={5}>
          Destaque
        </Text>
        <s.Card height="145px">
          <AudioInfo loading={loading} imgWidth={100} imgHeight={100} playlist={playlist} />
        </s.Card>
      </s.Content>
    </s.Container>
  );
}

export function StreamLive() {
  const isLive = useSelector((state) => state.player.isLive);
  const dispatch = useDispatch();

  const loadLive = () => {
    if (!isLive) {
      dispatch(PlayerActions.setLive(true));
      dispatch(PlayerActions.setType("LIVE"));
    } else {
      dispatch(PlayerActions.setLive(false));
      dispatch(PlayerActions.setType("MINI"));
    }
  }

  return (
    <s.RadioLive>
        <s.Row>
          <s.Live>
            <p>Ao vivo</p>
          </s.Live>
          <h2>
            Rádio Luiza
          </h2>
        </s.Row>
        <s.Row>
          <s.Button onClick={loadLive}>
            {isLive ? 'Parar' : 'Ouvir agora'}
          </s.Button>
        </s.Row>
    </s.RadioLive>
  )
}
