import Sound from "react-sound";

export const Types = {
  LOAD: "player/LOAD",
  PLAY: "player/PLAY",
  PAUSE: "player/PAUSE",
  NEXT: "player/NEXT",
  PREV: "player/PREV",
  INCREMENT: "player/INCREMENT",
  DECREMENT: "player/DECREMENT",
  PLAYING: "player/PLAYING",
  HANDLE_POSITION: "player/HANDLE_POSITION",
  SET_POSITION: "player/SET_POSITION",
  SET_VOLUME: "player/SET_VOLUME",
  SET_TYPE: "player/SET_TYPE",
  UPDATE_SONG: "player/UPDATE_SONG",
  SET_BOOKMARK: "player/SET_BOOKMARK",
  LIVE: "player/LIVE",
};

const INITIAL_STATE = {
  currentSong: null,
  list: [],
  // status: Sound.status.PLAYING,
  status: Sound.status.PAUSED,
  position: null,
  duration: null,
  volume: 100,
  type: "MINI",
  isLive: false,
};

export default function player(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD: {
      const { timePlay, totalTime, completed } = action.payload.currentSong;
      const isCompleted = timePlay >= totalTime || completed;
      const currentSong = isCompleted
        ? { ...action.payload.currentSong, timePlay: 0, completed: false }
        : action.payload.currentSong;

      const position = isCompleted
        ? 0
        : action.payload.currentSong.timePlay || 0;
      return {
        ...state,
        currentSong,
        status: Sound.status.PLAYING,
        list: action.payload.list,
        position,
        duration: action.payload.currentSong.totalTime || 0,
      };
    }
    case Types.PLAY:
      return { ...state, status: Sound.status.PLAYING };
    case Types.PAUSE:
      return { ...state, status: Sound.status.PAUSED };
    case Types.NEXT: {
      const nextIndex = state.list.findIndex(
        (song) => song.id === state.currentSong.id
      );
      const next = state.list[nextIndex + 1];
      if (next) {
        return { ...state, currentSong: next, position: 0 };
      }
      const { currentSongFinished } = action.payload;
      if (currentSongFinished) {
        return { ...state, status: Sound.status.PAUSED };
      }

      return { ...state };
    }
    case Types.PREV: {
      const prevIndex = state.list.findIndex(
        (song) => song.id === state.currentSong.id
      );
      const prev = state.list[prevIndex - 1];
      if (prev) {
        return { ...state, currentSong: prev, position: 0 };
      }
      return { ...state };
    }
    case Types.INCREMENT: {
      const position = state.position + action.payload.increment;

      if (position > state.duration) {
        return { ...state, position: state.duration };
      }

      return { ...state, position };
    }
    case Types.DECREMENT: {
      const position = state.position - action.payload.decrement;

      if (position < 0) {
        return { ...state, position: 0 };
      }

      return { ...state, position };
    }
    case Types.PLAYING:
      const { currentSong, ...payload } = action.payload;
      return {
        ...state,
        ...payload,
      };
    case Types.HANDLE_POSITION:
      return {
        ...state,
        position: state.duration * action.payload.percentage,
      };
    case Types.SET_POSITION:
      return {
        ...state,
        position: state.duration * action.payload.percentage,
      };
    case Types.SET_VOLUME:
      return { ...state, volume: action.payload.volume };
    case Types.SET_TYPE:
      return { ...state, type: action.payload.type };
    case Types.UPDATE_SONG: {
      const { song } = action.payload;
      const matchSong = (s) =>
        s.id === song.id && s.playlistID === song.playlistID;

      if (state.list.findIndex((s) => matchSong(s)) > -1) {
        const list = state.list.map((s) =>
          matchSong(s) ? { ...s, ...song } : s
        );

        const fileIsInPlayer = matchSong(state.currentSong);

        return {
          ...state,
          list,
          ...(fileIsInPlayer && {
            currentSong: { ...state.currentSong, ...song },
          }),
        };
      }
      return state;
    }
    case Types.LIVE:
      return {
        ...state,
        isLive: action.isLive
      };
    default:
      return state;
  }
}

export const Creators = {
  loadSong: (currentSong, list) => ({
    type: Types.LOAD,
    payload: { currentSong, list },
  }),
  play: (payload) => ({
    type: Types.PLAY,
    payload,
  }),
  pause: (payload) => ({
    type: Types.PAUSE,
    payload,
  }),
  next: (payload) => ({
    type: Types.NEXT,
    payload,
  }),
  prev: (payload) => ({
    type: Types.PREV,
    payload,
  }),
  increment: (payload) => ({
    type: Types.INCREMENT,
    payload,
  }),
  decrement: (payload) => ({
    type: Types.DECREMENT,
    payload,
  }),
  playing: (payload) => ({
    type: Types.PLAYING,
    payload,
  }),
  handlePosition: (payload) => ({
    type: Types.HANDLE_POSITION,
    payload,
  }),
  setPosition: (payload) => ({
    type: Types.SET_POSITION,
    payload,
  }),
  setVolume: (volume) => ({
    type: Types.SET_VOLUME,
    payload: { volume },
  }),
  setType: (type) => ({
    type: Types.SET_TYPE,
    payload: { type },
  }),
  updateSong: (payload) => ({
    type: Types.UPDATE_SONG,
    payload,
  }),
  setBookmark: (payload) => ({
    type: Types.SET_BOOKMARK,
    payload,
  }),
  setLive: (isLive) => ({
    type: Types.LIVE,
    isLive
  })
};
