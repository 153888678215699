import React from "react";

import { SearchSVG } from "../../assets";

import * as s from "./styles";

function SearchInput({ ...props }) {
  return (
    <s.Container>
      <SearchSVG color="silver" />
      <s.Input {...props} placeholder="Pesquisar" />
    </s.Container>
  );
}

export default SearchInput;
