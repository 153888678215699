import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Creators as PlayerActions } from "../../store/ducks/player";

import { ControlButtonsSVG } from "../../assets";
import * as s from "./styles";

const {
  PlaySVG,
  PauseSVG,
  PrevSVG,
  NextSVG,
  DecrementSVG,
  IncrementSVG,
} = ControlButtonsSVG;

const Components = {
  play: { Component: PlaySVG, action: "play" },
  pause: { Component: PauseSVG, action: "pause" },
  prev: { Component: PrevSVG, action: "prev" },
  next: { Component: NextSVG, action: "next" },
  decrement: { Component: DecrementSVG, action: "decrement" },
  increment: { Component: IncrementSVG, action: "increment" },
};

export default function ({
  onClick,
  size = "large",
  type = "play",
  togglePlayPause,
  color,
  textCentered,
  value,
  ...props
}) {
  const dispatch = useDispatch();
  const { currentSong, position } = useSelector((state) => state.player);
  const { colors } = useSelector((state) => state.config);
  const Component = Components[type].Component;

  async function handleClick() {
    if (onClick) {
      await onClick();
    }
    if (currentSong && currentSong.id) {
      dispatch(
        PlayerActions[Components[type].action]({
          currentSong,
          position,
          [type]: value,
        })
      );
    }
  }

  return (
    <s.ControlButton
      type="button"
      size={size}
      color={color}
      colors={colors}
      onClick={handleClick}
      {...props}
    >
      <Component color={color} />
      {textCentered && <s.TextCentered colors={colors}>{textCentered}</s.TextCentered>}
    </s.ControlButton>
  );
}
