import styled, { css } from "styled-components";
import { colors, device, animations, config } from "../../../styles";

export const Container = styled.div`
  background: ${colors.alabaster};
  height: ${config.miniPlayerHeight};
  padding: 12px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1;
  bottom: calc(${config.tabBarHeight} + 1px);
  left: 0;
  right: 0;
  box-shadow: 0px -3px 10px rgba(176, 173, 173, 0.29),
    0px 1px 0px ${colors.gallery};
  animation: ${css`0.2s ${animations.slideInUpAnimation}`};

  @media ${device.fromMobileL} {
    max-width: ${device.size.mobileL};
    margin: 0 auto;
  }
`;

export const ProgressSliderTop = styled.div`
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;

  & > .rc-slider {
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${device.size.laptop};
`;

export const Current = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 50px;
    height: 50px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
`;
