import React from "react";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import Text from "../Text";
import Badge from "../Badge";

import { getNumberOfAudiosLabel, getTotalTimeLabel } from "../../utils";

import * as s from "./styles";

function RecommendedList({ title, onClick, items = [], withSkeleton, loading }) {
  const { coverUrl } = useSelector(state => state.auth.company);
  const list = !items.length && withSkeleton ? new Array(5).fill({}) : items;

  return (
    <s.Container>
      {title ?
        <Text fontSize="20" fontWeight={800} marginBottom={15}>
          {title}
        </Text> : <Skeleton width={150} />
      }
      <s.List showScrollbar={items && items.length}>
        {list.map((playlist, index) => {
          if (!Object.keys(playlist).length) {
            return <Skeleton width={200} height={200} />
          }

          if (playlist.active) {
            return (
              <s.Item onClick={() => onClick(playlist)} key={`recommended-${index}`} src={!loading && (playlist.coverUrl || coverUrl)}>
                <Text fontSize="16" fontWeight={800} color="white">
                  {playlist.name || <Skeleton />}
                </Text>
                {playlist.files ? (
                  <s.ItemBottom>
                    <s.AudioDetails>
                      <Text
                        fontSize="16"
                        fontWeight={800}
                        color="white"
                        marginBottom={5}
                      >
                        {getNumberOfAudiosLabel(playlist.files)}
                      </Text>
                      <Text fontSize="16" fontWeight={300} color="white">
                        {getTotalTimeLabel(playlist.files)}
                      </Text>
                    </s.AudioDetails>
                    {playlist.isNew && <Badge background="primary">NOVO</Badge>}
                  </s.ItemBottom>
                ) : (
                  <Skeleton width="100%" height={120} />
                )}
              </s.Item>
            )
          }

          return null
        }
        )}
      </s.List>
    </s.Container>
  );
}

export default RecommendedList;
