import styled from "styled-components";
import Text from "../../components/Text";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 5px;

  background-color: ${({ colors }) => colors.primary};
`;

export const Img = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background-color: ${({ colors }) => colors.white};
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const ImgWrapper = styled.div`
  width: 110px;
  height: 110px;
  border: 3px solid ${({ colors }) => colors.white};
  background: ${({ colors }) => colors.transparent};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;

  position: absolute;

  &:not(:last-of-type) {
    box-shadow: 7px 4px 10px rgba(0, 0, 0, 0.35);
    left: 0;
    z-index: 1;
  }

  &:last-of-type {
    right: 0;
  }
`;

export const UserData = styled.div`
  border-radius: 5px;
  border: 1px solid ${({ colors }) => colors.white};
  width: 150px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .react-loading-skeleton {
      width: 90%;
      height: 70%;
    }
  }

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

export const Name = styled(Text)`
  letter-spacing: 1px;
  text-overflow: ellipsis;
  max-width: 310px;
  overflow: hidden;
  white-space: nowrap;
`
