import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries2";

const sortByOrderFile = files => {
  if (!Array.isArray(files)) {
    return files
  }

  return files.map((f) => {
      if (!f.file.active) {
        return null;
      }

      return {
        ...f.file,
        orderPlaylistFile: f.orderPlaylist ? f.orderPlaylist : '0'
      }
    })
    .filter(file => file)
    .sort((a, b) => a.orderPlaylistFile - b.orderPlaylistFile);
}

export async function apiGetPlaylistType(id) {
  try {
    const {
      data: {
        getPlaylistType: type
      },
    } = await API.graphql(
      graphqlOperation(
        queries.getPlaylistType,
        {id}
      )
    );

    return type;
  } catch (error) {
    console.log("error in service apiGetPlaylistType >> ", error);
    return null;
  }
}

export async function apiGetAllPlaylistTypes() {
  try {
    const {
      data: {
        listPlaylistTypes: { items }
      },
    } = await API.graphql(
      graphqlOperation(queries.listPlaylistTypes)
    );

    return items;
  } catch (error) {
    console.log("error in service apiGetAllPlaylistTypes >> ", error);
    return null;
  }
}

export async function apiGetPlaylists(companyID) {
  try {
    const {
      data: {
        listPlaylists: { items },
      },
    } = await API.graphql(
      graphqlOperation(
        queries.listPlaylists,
        companyID && {
          filter: {
            companyID: {
              eq: companyID,
            },
          },
        }
      )
    );

    const playlists = items.map(({ playlistfiles, ...i }) => {
      return {
        ...i,
        files: sortByOrderFile(playlistfiles.items),
      }
    });

    console.log('playlists', playlists);

    return playlists;
  } catch (error) {
    console.log("error in service apiGetPlaylists >> ", error);
    return [];
  }
}

export async function apiGetPlaylists2(companyID) {
  try {
    const {
      data: {
        listPlaylistFiles: { items },
      },
    } = await API.graphql(
      graphqlOperation(
        queries.listPlaylistFiles,
        companyID && {
          filter: {
            companyID: {
              eq: companyID,
            },
          },
        }
      )
    );
    const playlists = items.map(({ playlistfiles, ...i }) => ({
      ...i,
      files: playlistfiles.items.map((f) => f.file),
    }));
    return playlists;
  } catch (error) {
    console.log("error in service apiGetPlaylists >> ", error);
    return [];
  }
}

export async function apiGetPlaylistById(id) {
  try {
    const {
      data: { getPlaylist: p },
    } = await API.graphql(graphqlOperation(queries.getPlaylist, { id }));

    const playlist = {
      ...p,
      files: sortByOrderFile(p.playlistfiles.items),
    }

    return playlist;
  } catch (error) {
    console.log("error in service apiGetPlaylists >> ", error);
    return [];
  }
}
