import { call, select, put, delay } from "redux-saga/effects";

import { Auth } from "aws-amplify";

import {
  getCognitoUser,
  apiSetUser,
  apiGetUser,
  apiGetUserAuth,
} from "../../services/amplify/auth";

import { apiListGlobalConfig } from "../../services/amplify/company";

import { Creators as AuthActions } from "../ducks/auth";
import { Creators as ConfigActions } from "../ducks/config";
import { Creators as ProfileActions } from "../ducks/profile";
import jwt_decode from "jwt-decode";

export function* setUp() {
  try {
    const start = new Date();
    const cognitoUser = yield call(getCognitoUser);
    let shastaUser = {};
    let company = {};
    let companyConfig = {};
    const { username } = cognitoUser || {};
    if (username) {
      const data = yield call(apiGetUser, cognitoUser.username);
      shastaUser = data.shastaUser;
      company = data.company;
      companyConfig = data.companyConfig;
    }
    const config = yield call(apiListGlobalConfig);

    const { colors } = yield select((state) => state.config);

    const { primaryColor, secondaryColor } = companyConfig || {};

    if (primaryColor) {
      Object.assign(colors, { primary: primaryColor });
    }
    if (secondaryColor) {
      Object.assign(colors, { secondary: secondaryColor });
    }
    yield put(ConfigActions.setConfig({ ...config, colors }));

    yield put(AuthActions.setCognitoUser({ cognitoUser, shastaUser, company }));

    yield put(ProfileActions.getMetricsRequest());

    const timeRequest = new Date() - start;
    const timeout = 2000 - timeRequest;
    const timeoutDelay = timeout > 0 ? timeout : 0;
    yield delay(timeoutDelay);
    yield put(AuthActions.setSetUpState({ isLoading: false }));
  } catch (error) {
    console.log("error in saga setUp >> ", error);
    yield put(AuthActions.setSetUpState({ isLoading: false }));
  }
}

export function* checkFederatedSignIn({ payload }) {
  console.log('payload checkFederatedSignIn =======>')
  try {
    const cognitoUser = yield Auth.currentAuthenticatedUser();
    console.log('cognitoUser :>> ', cognitoUser);

    if (!cognitoUser) {
      return;
    }

    console.log('cognitoUser :>> ', cognitoUser, payload);

    yield put(AuthActions.signInRequest({ federatedUser: cognitoUser, onSuccess: payload.onSuccess }));
  } catch (error) {
    console.log('error in checkFederatedSignIn :>> ', error);
    return
  }
}

export function* signIn({ payload }) {
  const { email, password, onSuccess, onError, federatedUser } = payload;
  console.log('entrou aqui? =========>', payload);
  try {
    if (email && password && !federatedUser) {
      try {
        // LOGIN
        const user = yield Auth.signIn(email, password);
        console.log(user);
        // yield Auth.completeNewPassword(user, 'Shasta123')
      } catch (error) {
        try {
          // SE NÃO CONSEGUIU LOGAR, VERIFICA NA UserAuth
          const userAuth = yield call(apiGetUserAuth, email) || {};
          if (userAuth && userAuth.id) {
            // SE ACHOU ALGUÉM, LOGA E AZAR -\o/-
            const { user: newCognitoUser } = yield Auth.signUp({
              username: email,
              password,
            });
            const usr = yield Auth.signIn(email, password);
          } else {
            // SE NÃO, JOGA UM ERRO E AZAR
            throw new Error('')
          }
        } catch (error) {
          throw new Error(error)
        }
      }
    }

    console.log('=====================');

    const cognitoUser = federatedUser || (yield call(getCognitoUser));

    console.log('cognitoUser ====>', cognitoUser);
    console.log('=====================');

    const groups =
      cognitoUser.Session?.accessToken?.payload["cognito:groups"] || [];

    console.log('groups =====>', groups);
    console.log('=====================');

    const decodedToken = jwt_decode(cognitoUser?.signInUserSession?.idToken?.jwtToken);

    const input = {
      id: cognitoUser.username,
      name: cognitoUser.attributes?.name || decodedToken?.name,
      cognitoPoolID: cognitoUser.pool?.userPoolId,
      cognitoGroupName: groups[0],
      lastLogin: Date.now,
      email: cognitoUser.attributes?.email || decodedToken?.email,
      active: true,
    };

    console.log('input =====>', input);
    console.log('=====================');

    const { shastaUser, company, companyConfig } = yield call(
      apiSetUser,
      input
    );

    console.log('shastaUser, company, companyConfig ======>', shastaUser, company, companyConfig);
    console.log('=====================');

    const config = yield call(apiListGlobalConfig);

    console.log('config ======>', config);
    console.log('=====================');

    const { colors } = yield select((state) => state.config);

    console.log('colors ======>', colors);
    console.log('=====================');

    const { primaryColor, secondaryColor } = companyConfig || {};

    console.log('primaryColor, secondaryColor ======>', primaryColor, secondaryColor);
    console.log('=====================');

    if (primaryColor) {
      Object.assign(colors, { primary: primaryColor });
    }
    if (secondaryColor) {
      Object.assign(colors, { secondary: secondaryColor });
    }

    console.log('onSuccess ======>', onSuccess);

    if (onSuccess) {
      onSuccess();
    }

    yield put(ConfigActions.setConfig({ ...config, colors }));

    yield put(AuthActions.setCognitoUser({ cognitoUser, shastaUser, company }));

    yield put(ProfileActions.getMetricsRequest());
  } catch (error) {
    console.log(error);
    const message =
      error.code === "NotAuthorizedException"
        ? "E-mail ou senha incorretos"
        : "Ops! Ocorreu um problema no sistema";
    yield put(
      AuthActions.setSignInState({
        error: message,
        isOut: false,
        isLoading: false,
      })
    );
    if (onError) {
      onError();
    }
    console.log("error in saga signIn >> ", error);
  }
}
