import styled from 'styled-components';
import { colors } from "../../styles";

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const Input = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  border-bottom: 1px solid ${colors.dustyGray};
  font-size: 16px;
  font-weight: 800;
  padding: 10px 0;
`;