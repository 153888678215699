import React from "react";
import Skeleton from "react-loading-skeleton";

import Text from "../Text";
import AudioInfo from "../AudioInfo";

import * as s from "./styles";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

export default function GenericList({
  onClick,
  title,
  items,
  withSkeleton,
  headerRight,
  hasBadge,
  loading,
  inline = false,
}) {
  const list = !items.length && withSkeleton ? new Array(2).fill({}) : items;
  return (
    <s.Container>
      <s.ListHeader>
        <Text fontSize="20" fontWeight={800}>
          {title}
        </Text>
        {headerRight}
      </s.ListHeader>
      <s.List className={`${inline ? 'inline' : 'not-inline'}`}>
        {list.map((playlist) => {
          if (!Object.keys(playlist).length && inline) {
            return <Skeleton width={200} height={200} />
          }

          if (inline) {
            return (
              <s.Item >
                <Link to={`${routes.library}?tag=${playlist.id}`}>
                  <Text color="primary" fontSize="18" fontWeight={700}>{playlist.name}</Text>
                  <Text fontSize="12" fontWeight={400}>{playlist.numberOfAlbums} {`${playlist?.numberOfAlbums === 1 ? 'álbum' : 'álbuns'}`}</Text>
                </Link>
              </s.Item>
            )
          }

          if (playlist.active) {
            return (
              <AudioInfo
                loading={loading}
                key={playlist.id}
                onClick={() => onClick(playlist)}
                imgRadius={10}
                imgWidth={80}
                imgHeight={80}
                playlist={playlist}
                hasBadge={hasBadge}
              />
            )
          }

          return null
        }
        )}
      </s.List>
    </s.Container>
  );
}
