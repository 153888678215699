import React from "react";

import Text from "../Text";

import i, { MicrophoneSVG } from "../../assets";
import * as s from "./styles";

export function Mountain({ ...props }) {
  return <s.ImgMountain {...props} src={i.imgMountain}></s.ImgMountain>;
}

export function View({ children, ...props }) {
  return <s.View {...props}>{children}</s.View>;
}

function ShastaLibrary({ ...props }) {
  return (
    <s.Container {...props}>
      <MicrophoneSVG color="shastaPrimary" />
      <img src={i.imgLogo} alt="" />
      <Text color="shastaPrimary" fontSize="24">
        library
      </Text>
    </s.Container>
  );
}

export default ShastaLibrary;
