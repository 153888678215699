import { call, put, select } from "redux-saga/effects";

import { apiSetBookmark } from "../../services/amplify/bookmark";

import { Creators as PlayerActions } from "../ducks/player";
import { Creators as PlaylistActions } from "../ducks/playlists";
import { Creators as PlaylistDetailsActions } from "../ducks/playlistDetails";
import { Creators as ErrorActions } from "../ducks/error";

export function* setBookmark({ payload }) {
  console.log("setBookmark payload :>> ", payload);
  try {
    const { file } = payload;
    const active = !Boolean(file.bookmarked);

    // SET LISTS (MY LIST, PLAYER, DETAILS)
    const song = {
      ...file,
      bookmarked: active,
    };
    yield put(PlayerActions.updateSong({ song }));
    yield put(PlaylistDetailsActions.updateSong({ song }));
    yield put(PlaylistActions.setAudioInMyList(song));

    const userid = yield select((state) => state.auth.attributes.id);

    const bookmark = yield call(apiSetBookmark, {
      id: file.bookmarkID,
      userid,
      fileid: file.id,
      playlistid: file.playlistID,
      active,
    });

    // SET LISTS (MY LIST, PLAYER, DETAILS)
    const newSong = {
      ...file,
      bookmarked: active,
      bookmarkID: bookmark.id,
    };
    yield put(PlayerActions.updateSong({ song: newSong }));
    yield put(PlaylistDetailsActions.updateSong({ song: newSong }));
    yield put(PlaylistActions.setAudioInMyList(newSong));
  } catch (error) {
    console.log("error in saga setBookmark >> ", error);
  }
}
