import React from 'react';
import useGlobalContext from '../../contexts/GlobalContext';
import { useSelector } from "react-redux";

import * as s from "./styles";

const PopUp = ({ children }) => {
  const { popUp } = useGlobalContext();
  const { colors } = useSelector((state) => state.config);

  return (
    <s.Overlay show={popUp.isVisible}>
      <s.PopUp isOpen={popUp.isVisible}>
        <s.Wrapper companyColors={colors}>
          <s.ClosePopUp onClick={() => popUp.close()} />
          {children}
        </s.Wrapper>
      </s.PopUp>
    </s.Overlay>
  )
}

export default PopUp;
