import styled from "styled-components";
import { colors } from "../../styles";

export const Overlay = styled.div`
  align-items: center;
  background-color: rgb(0 0 0 / .7);
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 100vh;
  display: ${({ show }) => show ? 'flex' : 'none'};
`;

export const PopUp = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  max-width: 350px;
  min-height: 300px;
  padding: 35px;
  position: relative;
  text-align: center;
  width: 95%;
`;

export const Wrapper = styled.div`
  h1, h2, h3 {
    color: ${({ companyColors }) => companyColors?.primary || colors.primary};
    margin-bottom: 10px;
  }

  & > *:last-child {
    margin-top: 10px;
  }
`

export const ClosePopUp = styled.span`
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;

  &:hover {
    &:after,
    &:before {
      background-color: ${({ companyColors }) => companyColors?.primary || colors.primary};
      transition: all 0.3s ease-in-out;
    }
  }

  &:after,
  &:before {
    background-color: ${colors.secondary};
    content: '';
    position: relative;
    transform: rotate(45deg);
    transition: all 0.3s ease-in-out;
  }

  &:after {
    height: 2px;
    left: 0px;
    top: 7px;
    width: 16px;
  }

  &:before {
    height: 16px;
    left: 9px;
    top: 0;
    width: 2px;
  }
`
