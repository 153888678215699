import React from "react";
import { useLocation } from "react-router-dom";

import Text from "../Text";

import { routes } from "../../routes";

import { HomeSVG, LibrarySVG, StarSVG, ProfileSVG } from "../../assets";
import * as s from "./styles";

function TabBar() {
  let location = useLocation();

  function getColor(to) {
    if (to === location.pathname) {
      return "primary";
    }

    return "secondary";
  }

  const items = {
    Inicio: {
      to: routes.home,
      Icon: HomeSVG,
    },
    Biblioteca: {
      to: routes.library,
      Icon: LibrarySVG,
    },
    "Minha lista": {
      to: routes.myList,
      Icon: StarSVG,
    },
    "Meu Perfil": {
      to: routes.profile,
      Icon: ProfileSVG,
    },
  };

  return (
    <s.Container>
      {Object.entries(items).map(([key, { to, Icon }], i) => (
        <s.LinkItem key={`tab-item-${i}`} to={to}>
          <Icon color={getColor(to)} />
          <Text color={getColor(to)} fontSize={13}>{key}</Text>
        </s.LinkItem>
      ))}
    </s.Container>
  );
}

export default TabBar;
