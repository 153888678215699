export const Types = {
  GET_AUDIOS_REQUEST: "playlistDetails/GET_AUDIOS_REQUEST",
  GET_AUDIOS_SUCCESS: "playlistDetails/GET_AUDIOS_SUCCESS",
  SET_PLAYLIST: "playlistDetails/SET_PLAYLIST",
  UPDATE_SONG: "playlistDetails/UPDATE_SONG",
};

const INITIAL_STATE = {
  playlistDetails: {},
  audios: [],
  loading: false,
};

export default function playlistDetails(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_AUDIOS_REQUEST:
      const { loading } = action.payload
      return { ...state, loading: loading || !state.audios.length };
    case Types.GET_AUDIOS_SUCCESS:
      return { ...state, loading: false, audios: action.payload };
    case Types.SET_PLAYLIST:
      return {
        ...state,
        playlistDetails: action.payload,
        audios: action.payload.files || state.audios,
        loading: !state.audios.length
      };
    case Types.UPDATE_SONG: {
      const { song } = action.payload;
      const matchSong = (s) => s.id === song.id;

      if (state.audios.findIndex((s) => matchSong(s)) > -1) {
        const audios = state.audios.map((s) =>
          matchSong(s) ? { ...s, ...song } : s
        );

        return { ...state, audios };
      }
      return state;
    }
    default:
      return state;
  }
}

export const Creators = {
  getAudiosRequest: (payload) => ({
    type: Types.GET_AUDIOS_REQUEST,
    payload,
  }),
  getAudiosSuccess: (payload) => ({
    type: Types.GET_AUDIOS_SUCCESS,
    payload,
  }),
  setPlaylistDetails: (payload) => ({
    type: Types.SET_PLAYLIST,
    payload,
  }),
  updateSong: (payload) => ({
    type: Types.UPDATE_SONG,
    payload,
  }),
};
