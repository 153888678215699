import React from 'react';
import { createContext, useState, useContext } from 'react';
import { element } from 'prop-types';

const GlobalContext = createContext({})

export const GlobalProvider = ({ children }) => {
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  return (
    <GlobalContext.Provider value={{
      popUp: {
        close: () => setPopUpVisible(false),
        isVisible: isPopUpVisible,
        show: () => setPopUpVisible(true),
      }
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default function useGlobalContext() {
  const context = useContext(GlobalContext)

  return context
}

GlobalProvider.propTypes = {
  children: element.isRequired
}
