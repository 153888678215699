import React from "react";
import { useSelector } from "react-redux";

// import { Container } from './styles';

function StarOutlinedSVG({ color }) {
  const { colors } = useSelector((state) => state.config);
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3435 18.4831L4.34364 18.4837C4.29113 18.51 4.24 18.5043 4.19119 18.4656C4.14007 18.4251 4.10491 18.3553 4.12087 18.2647L4.12092 18.2644L5.17221 12.2732L5.2185 12.0095L5.02461 11.8248L0.562632 7.57433C0.562583 7.57429 0.562534 7.57424 0.562484 7.57419C0.505489 7.51969 0.485488 7.44006 0.510099 7.35744C0.534499 7.27554 0.587327 7.23605 0.645486 7.22787L0.646218 7.22777L6.85015 6.3462L7.10856 6.30948L7.22617 6.07647L9.9924 0.595924C10.0292 0.523133 10.0841 0.5 10.1332 0.5C10.1823 0.5 10.2372 0.523133 10.274 0.595923L13.0402 6.07647L13.1578 6.30948L13.4162 6.3462L19.6202 7.22777L19.6209 7.22787C19.6791 7.23605 19.7319 7.27554 19.7563 7.35744C19.7809 7.44012 19.7609 7.5198 19.7038 7.5743C19.7038 7.57431 19.7038 7.57432 19.7038 7.57433L15.2418 11.8248L15.0479 12.0095L15.0942 12.2732L16.1455 18.2644L16.1455 18.2647C16.1615 18.3553 16.1263 18.425 16.0752 18.4656C16.026 18.5046 15.9744 18.5101 15.9214 18.483C15.9213 18.483 15.9213 18.4829 15.9212 18.4829L10.3598 15.6256L10.1312 15.5081L9.90271 15.6256L4.3435 18.4831Z"
        stroke={fill}
      />
    </svg>
  );
}

export default StarOutlinedSVG;
