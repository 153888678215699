import styled from "styled-components";

export const Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  &:link {
    text-decoration: inherit;
    color: inherit;
  }

  &:visited {
    text-decoration: inherit;
    color: inherit;
  }
`;

export const AudioInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  flex: 1;
  width: 100%;
  height: 100%;

  & > :not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const Img = styled.div`
  min-width: ${({ width }) => width || 70}px;
  min-height: ${({ height }) => height || 70}px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: ${({ radius }) => radius || 5}px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;