import styled, { css } from "styled-components";
import { colors, animations } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  & svg {
    cursor: pointer;
  }

  & > * {
    padding-top: 10px;
  }

  & > :not(:last-child) {
    padding-bottom: 10px;
  }
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  & > :first-child {
    width: 20%;
  }
  & > :last-child {
    width: 70%;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  &:nth-child(even) {
    background: ${colors.gallery};
  }

  &:nth-child(odd) {
    background: ${colors.mercury3};
  }

  & > div {
    display: flex;
    justify-content: space-around;
    max-width: 400px;
    width: 100%;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Img = styled.img`
  width: 50px;
  height: 50px;
`;

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > svg {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
  }
`;
