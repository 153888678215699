import { call, put, select } from "redux-saga/effects";

import { apiGetPlaylists } from "../../services/amplify/playlists";
import { apiGetMyList } from "../../services/amplify/bookmark";

import { Creators as PlaylistsActions } from "../ducks/playlists";
import { Creators as ErrorActions } from "../ducks/error";

export function* getPlaylists() {
  try {
    const { id } = yield select((state) => state.auth.company);
    const playlists = yield call(apiGetPlaylists, id);
    yield put(PlaylistsActions.getPlaylistsSuccess(playlists));
  } catch (error) {
    console.log("error in saga getPlaylists >> ", error);
  }
}

export function* getMyList() {
  try {
    const { id } = yield select((state) => state.auth.attributes);
    const myList = yield call(apiGetMyList, id);
    yield put(PlaylistsActions.getMyListsSuccess(myList));
  } catch (error) {
    console.log("error in saga getMyList >> ", error);
  }
}
