import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { routes } from "../../routes";

export function ScrollToTopControlller () {
  const location = useLocation();

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [location]);
  return null;
};


export function PrivateRoute({
  component: Component,
  _isAuth,
  pathname: redirect,
  ...rest
}) {
  const cognitoSession = useSelector((state) => state.auth.cognitoSession);
  const cognitoUser = useSelector((state) => state.auth.cognitoUser);
  const isAuth =
    (cognitoSession && cognitoSession.isValid()) || (cognitoUser && Object.keys(cognitoUser).length);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to={routes.login} />
      }
    />
  );
}

export function UnauthenticatedRoute({
  component: Component,
  _isAuth,
  pathname: redirect,
  ...rest
}) {
  const cognitoSession = useSelector((state) => state.auth.cognitoSession);
  const cognitoUser = useSelector((state) => state.auth.cognitoUser);
  const isAuth =
    (cognitoSession && cognitoSession.isValid()) || (cognitoUser && Object.keys(cognitoUser).length);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth ? <Component {...props} /> : <Redirect to={routes.home} />
      }
    />
  );
}

export default Route;
