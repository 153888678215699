import styled, { css } from "styled-components";
import { fonts } from "../../styles";

export const defaultStyle = css``;

export const Button = styled.button`
  font-family: ${fonts.primary};
  width: ${({ width }) => width || "300px"};
  height: ${({ height }) => height || 60}px;
  font-weight: ${({ fontWeight }) => fontWeight || "800"};
  font-size: ${({ fontSize }) => fontSize || "24px"};
  color: ${({ color, colors }) => colors[color] || color || colors.white};
  background: ${({ background, colors }) =>
    colors[background] || background || colors.primary};
  border-radius: 10px;
  border: ${({ border, colors }) =>
    border ? `1px solid ${colors[border] || border}` : "none"};
  cursor: pointer;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`;
