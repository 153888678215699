import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Creators as PlayerActions } from "../../../store/ducks/player";
import { Creators as PlaylistDetailsActions } from "../../../store/ducks/playlistDetails";
import { Block } from "../../../styles/components";

import i, { ArrowDownSVG } from "../../../assets";

import Text from "../../Text";
import Bookmark from "../../Bookmark";

import { getProgress } from "../../../utils";

import * as s from "./styles";

import ProgressSlider from "../ProgressSlider";
import ControlButton from "../ControlButtons";

import { routes } from "../../../routes";

export default function Player({ properties }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { colors } = useSelector((state) => state.config);
  const { coverUrl } = useSelector((state) => state.auth.company);

  const {
    player,
    position,
    timeLeft,
    incrementMiliSeconds,
    incrementSeconds,
  } = properties;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  async function closeFullPlayer() {
    await dispatch(PlayerActions.setType("MINI"));
  }

  async function goToAlbum() {
    await closeFullPlayer();
    await dispatch(
      PlaylistDetailsActions.setPlaylistDetails({
        ...player.currentSong,
        id: null,
        name: player.currentSong.album,
        coverUrl: player.currentSong.thumbnail,
        newPlaylistId: player.currentSong.playlistID,
      })
    );
    history.push(routes.album);
  }

  return (
    <s.Container>
      <s.Content>
        <Block isColumn alignItemsCenter width="100%">
          <s.Header>
            <s.Btn onClick={closeFullPlayer}>
              <ArrowDownSVG color="primary" />
            </s.Btn>
            <Text fontSize="14px" fontWeight={300} color="white">
              Ouvindo agora
            </Text>
          </s.Header>
          <Text
            fontSize="16px"
            fontWeight={800}
            color="primary"
            onClick={goToAlbum}
          >
            {player.currentSong.album}
          </Text>
        </Block>

        <s.Img
          src={player.currentSong.thumbnail || coverUrl}
          alt={player.currentSong.title}
        />

        <Block isColumn alignItemsCenter width="100%">
          <s.Title>
            <Text fontSize="16px" fontWeight={800} color="primary">
              <b>#{player.currentSong.index}</b> {player.currentSong.title}
            </Text>
            <Bookmark file={player.currentSong} />
          </s.Title>

          <s.TimeWrapper>
            <ProgressSlider
              progress={getProgress(player)}
              song={player.currentSong}
              railColor={colors.silver}
              trackColor={colors.primary}
            />
            <s.Time>
              <Text fontSize="10" fontWeight={300} color="silver">
                {position}
              </Text>
              <Text fontSize="10" fontWeight={300} color="silver">
                {timeLeft !== "00:00" ? "-" : ""}
                {timeLeft}
              </Text>
            </s.Time>
          </s.TimeWrapper>
        </Block>

        <s.Controls>
          <ControlButton
            textCentered={incrementSeconds}
            value={incrementMiliSeconds}
            size="xlarge"
            color="primary"
            type="decrement"
          />
          <div>
            <ControlButton size="medium" color="primary" type="prev" />
            <ControlButton
              withBorder
              color="primary"
              size="xxlarge"
              type={
                player.currentSong && player.status === "PLAYING"
                  ? "pause"
                  : "play"
              }
            />
            <ControlButton size="medium" color="primary" type="next" />
          </div>
          <ControlButton
            textCentered={incrementSeconds}
            value={incrementMiliSeconds}
            size="xlarge"
            color="primary"
            type="increment"
          />
        </s.Controls>
      </s.Content>
      <s.Background src={i.imgMountainGrayscale} />
    </s.Container>
  );
}
