import React from "react";
import { useDispatch } from "react-redux";

import { Creators as PlayerActions } from "../../store/ducks/player";

import { StarSVG, StarOutlinedSVG } from "../../assets";
import * as s from "./styles";

function Bookmark({ file = {} }) {
  const dispatch = useDispatch();

  function setBookmark() {
    dispatch(PlayerActions.setBookmark({ file }));
  }

  const Component = file.bookmarked ? StarSVG : StarOutlinedSVG;

  return (
    <s.Button onClick={setBookmark}>
      <Component color="primary" />
    </s.Button>
  );
}

export default Bookmark;
