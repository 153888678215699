import React from "react";

import ShastaLibrary, { View, Mountain } from "../../components/ShastaLibrary";

import * as s from "./styles";

function Splash() {
  return (
    <View isSplash>
      <ShastaLibrary align="middle" />
      <Mountain animation="pulse" align="bottom" />
    </View>
  );
}

export default Splash;
