import React, { useState, useEffect, useRef } from "react";

import * as s from "./styles";

function Modal({ children, close, isOpen, ...props }) {
  const modalRef = useRef(null);
  const [overlayOpen, setOverlayOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOverlayOpen(true);
    }
  }, [isOpen]);

  function closeModal(event) {
    if (
      modalRef &&
      modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      close();
      setTimeout(() => {
        setOverlayOpen(false);
      }, 200);
    }
  }

  return (
    <s.Overlay isOverlayOpen={overlayOpen} onClick={closeModal}>
      <s.Modal ref={modalRef} isOpen={isOpen} {...props}>
        {children}
      </s.Modal>
    </s.Overlay>
  );
}

export default Modal;
