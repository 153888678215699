import styled from "styled-components";
import { device } from "../../styles";
import { shastaAnimations } from "../../components/ShastaLibrary/styles";

export const HalfView = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  overflow: hidden;

  &:first-of-type {
    height: 30%;
  }

  &:last-of-type {
    height: 70%;
    justify-content: flex-start;
    @media ${device.fromMobileM} {
      & > button {
        margin-bottom: 20px;
      }
    }
  }
  ${({ animation }) => animation && shastaAnimations[animation]}
`;

export const WrapperButton = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 3;
  min-height: 250px;

  & > button:first-of-type {
    margin-bottom: 20px;
  }
`
export const ImgMountain = styled.div`
  width: 100%;
  height: 250px;
  position: absolute;
  z-index: 1;
  background-image: url(${({ src }) => src});
  background-position-x: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -10vh;

  @media (min-width: ${device.screen.mobileL}px) {
    bottom: -8vh;
  }
`
