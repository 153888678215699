import React from "react";
import { useSelector } from "react-redux";

// import { Container } from './styles';

function LibrarySVG({ color }) {
  const { colors } = useSelector((state) => state.config);
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6467 5.25H2.35328C1.60588 5.25 1 5.85588 1 6.60328V15.8967C1 16.6441 1.60588 17.25 2.35328 17.25H17.6467C18.3941 17.25 19 16.6441 19 15.8967V6.60328C19 5.85588 18.3941 5.25 17.6467 5.25Z"
        fill={fill}
        stroke={fill}
        strokeLinejoin="round"
      />
      <path d="M4.75 0.75H15.25H4.75Z" fill={fill} />
      <path
        d="M4.75 0.75H15.25"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path d="M3.25 3H16.75H3.25Z" fill={fill} />
      <path
        d="M3.25 3H16.75"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default LibrarySVG;
