export function msToTime(ms) {
  if (!ms) return "00:00";
  let seconds = parseInt((ms / 1000) % 60, 10);
  let minutes = parseInt((ms / (1000 * 60)) % 60, 10);
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${minutes}:${seconds}`;
}

function msToHM(ms) {
  let seconds = ms / 1000;
  const hours = parseInt(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = parseInt(seconds / 60);
  seconds = seconds % 60;
  return `${hours > 0 ? `${hours}h` : ""}${minutes}min`;
}

export function getProgress({ position = 0, duration }) {
  return parseInt(position * (1000 / duration), 10) || 0;
}

export function getNumberOfAlbumsLabel(playlists = []) {
  const length = playlists.length;
  const label = length === 1 ? "álbum" : "álbuns";
  return `${length} ${label}`;
}

export function getNumberOfAudiosLabel(files = []) {
  const length = files.length;
  const label = length === 1 ? "áudio" : "áudios";
  return `${length} ${label}`;
}

export function getTotalTimeLabel(files = []) {
  const sum = sumItemsInArray(files, "totalTime");
  return msToHM(sum);
}

export function sumItemsInArray(items, objKey) {
  return items.reduce((a, b) => a + b[objKey], 0) || 0;
}

export function getPlaylistAttributes(playlists) {
  return {
    album: playlists.name,
    thumbnail: playlists.coverUrl,
    playlistID: playlists.id,
    desc: playlists.desc
  };
}

export function autocomplete(data, term, keys) {
  return data.filter((option) => {
    return (
      keys.filter(
        (key) =>
          option[key].toString().toLowerCase().indexOf(term.toLowerCase()) >= 0
      ).length > 0
    );
  });
}

export function capitalizeAndUseFirstName(name) {
  if (name?.length) {
    const [firstName] = name.split(' ');
    const capitalizedName = firstName[0].toUpperCase() + firstName.slice(1).toLowerCase()
    return capitalizedName
  }

  return name
}

export function isMagaluUrl() {
  const [ companyDomain ] = window.location.host.split('.');
  const isMagalu = companyDomain.includes('radioluizadigital') || companyDomain.includes('dev') || companyDomain.includes('localhost');
  return isMagalu;
}
