import imgLogo from "./images/logo.png";
import imgMountain from "./images/mountain.png";
import imgMountainGrayscale from "./images/mountain_grayscale.png";
import VolumeIcon from "./images/volume.svg";
import ShufflerIcon from "./images/shuffle.svg";
import BackwardIcon from "./images/backward.svg";
import ForwardIcon from "./images/forward.svg";
import RepeatIcon from "./images/repeat.svg";

import MicrophoneSVG from "./MicrophoneSVG";
import HomeSVG from "./HomeSVG";
import StarOutlinedSVG from "./StarOutlinedSVG";
import LibrarySVG from "./LibrarySVG";
import StarSVG from "./StarSVG";
import ProfileSVG from "./ProfileSVG";
import * as ControlButtonsSVG from "./ControlButtonsSVG";
import ArrowBackSVG from "./ArrowBackSVG";
import ArrowDownSVG from "./ArrowDownSVG";
import SearchSVG from "./SearchSVG";

export {
  MicrophoneSVG,
  HomeSVG,
  StarOutlinedSVG,
  LibrarySVG,
  StarSVG,
  ProfileSVG,
  ControlButtonsSVG,
  ArrowBackSVG,
  ArrowDownSVG,
  SearchSVG,
};

export default {
  imgLogo,
  imgMountain,
  imgMountainGrayscale,
  VolumeIcon,
  ShufflerIcon,
  BackwardIcon,
  ForwardIcon,
  RepeatIcon,
};
