import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Text from "../Text";

import i, { MicrophoneSVG } from "../../assets";
import * as s from "./styles";
import useGlobalContext from '../../contexts/GlobalContext';
import PopUp from "../PopUp";

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

function PageTitle({ children }) {
  return (
    children && (
      <s.PageTitle>
        <Text color="white" fontSize="20" fontWeight={800}>
          {children}
        </Text>
        <MicrophoneSVG color="white" />
      </s.PageTitle>
    )
  );
}

function Layout({
  children,
  headerComponent,
  pageTitle,
  headerHeight,
  mountainVAlign,
}) {
  const { colors } = useSelector((state) => state.config);
  const { popUp } = useGlobalContext()
  const { attributes } = useSelector((state) => state.auth);
  const [ companyDomain ] = window.location.host.split('.');

  useEffect(() => {
    const now = new Date();
    const createdAt = new Date(attributes?.createdAt);
    if ( datesAreOnSameDay(now, createdAt) ) {
      popUp.show();
    }
  }, [attributes]);

  const welcomePopUp = () => {
    switch(companyDomain) {
      case 'radioluizadigital':
        return (
          <>
            <h3>Que ótimo ter você aqui na nossa Rádio Luiza Mobile!</h3>
            <Text>Aqui a Rádio Luiza fala direto com você, te ajudando a vender mais e a estar sempre por dentro de tudo o que acontece no Magalu!</Text>
            <Text><strong>Fique ligado!</strong></Text>
          </>
        )
      case 'localhost:3000':
        return (
          <>
            <h3>Que ótimo ter você aqui na nossa Rádio Luiza Mobile!</h3>
            <Text>Aqui a Rádio Luiza fala direto com você, te ajudando a vender mais e a estar sempre por dentro de tudo o que acontece no Magalu!</Text>
            <Text><strong>Fique ligado!</strong></Text>
          </>
        );
      case 'dev':
        return (
          <>
            <h3>Que ótimo ter você aqui na nossa Rádio Luiza Mobile!</h3>
            <Text>Aqui a Rádio Luiza fala direto com você, te ajudando a vender mais e a estar sempre por dentro de tudo o que acontece no Magalu!</Text>
            <Text><strong>Fique ligado!</strong></Text>
          </>
        );
      default:
        return null;
    }
  }

  const showWelcomePopUp = welcomePopUp() && popUp.isVisible

  return (
    <>
      <s.Page>
        {showWelcomePopUp &&
          <PopUp>
            {welcomePopUp()}
          </PopUp>
        }
        <s.PageHeader colors={colors} headerHeight={headerHeight}>
          <s.HeaderContent
            mountainVAlign={mountainVAlign}
            src={i.imgMountainGrayscale}
          >
            {headerComponent || <PageTitle>{pageTitle}</PageTitle>}
          </s.HeaderContent>
        </s.PageHeader>
        <s.PageBody colors={colors}>{children}</s.PageBody>
      </s.Page>
    </>
  );
}

export default Layout;
