import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as PlayerActions } from "../../../store/ducks/player";
import { apiSetPlay } from '../../../services/amplify/play';

import Text from "../../Text";

import * as s from "./styles";

import { ControlButtonsSVG } from "../../../assets";

const {
  PlaySVG,
  PauseSVG,
} = ControlButtonsSVG;

export default function LivePlayer({ properties, setStopedLive }) {
  const [toggleAudioLive, setToggleAudioLive] = useState(false);
  const isLive = useSelector((state) => state.player.isLive);
  const currentSong = useSelector((state) => state.player.currentSong);
  const { coverUrl } = useSelector(state => state.auth.company);
  const dispatch = useDispatch();
  const audioRef = useRef();
  const buttonRef = useRef(null);
  const userId = useSelector((state) => state.auth.attributes.id);
  const [counter, setCounter] = useState(0);
  const [isCounterRunning, setIsCounterRunning] = useState(false);

  const fetchPlay = useCallback(async () => {
    const input = {
      userid: userId,
      fileid: 'radioaovivo',
      playlistid: 'radioaovivo',
      timePlay: counter,
      completed: false,
    };

    await apiSetPlay(input).then(() => {
      if (counter !== 0) {
        setCounter(0);
        setIsCounterRunning(false);
      }
    });
  }, [counter, userId]);

  useEffect(() => {
    if (toggleAudioLive && counter === 0) {
      fetchPlay();
    }
  }, [counter, fetchPlay, toggleAudioLive]);

  useEffect(() => {
    let intervalId
    if (toggleAudioLive && audioRef?.current.played) {
      intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter + 1);
        setIsCounterRunning(true);
      }, 10);
    } else {
      setIsCounterRunning(false);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [audioRef, counter, toggleAudioLive]);

  useEffect(() => {
    if (audioRef?.current) {
      if (toggleAudioLive) {
        dispatch(PlayerActions.setType("LIVE"));
        audioRef.current.play();
        setStopedLive(false);
        if (currentSong) {
          dispatch(PlayerActions.pause());
        }
      } else {
        setStopedLive(true);
        dispatch(PlayerActions.setType('MINI'));
        audioRef.current.pause();
      }
    }
  }, [audioRef, currentSong, dispatch, toggleAudioLive, setStopedLive]);

  useEffect(() => {
    if (audioRef?.current.paused && audioRef?.current.played.length && !isCounterRunning && counter !== 0) {
      fetchPlay();
    }
  }, [audioRef, counter, fetchPlay, isCounterRunning]);

  useEffect(() => {
    setToggleAudioLive(isLive);
  }, [isLive]);

  const handleClick = () => {
    setToggleAudioLive(!isLive || !toggleAudioLive);
  }


  return (
    <s.Container isNotLive={!isLive}>
      <s.Content>
        <s.Current>
          <s.Picture>
            <img src={coverUrl} alt="" />
          </s.Picture>
          <audio ref={audioRef} controls>
            <source src="https://centova4.transmissaodigital.com:20100/stream" type="audio/mpeg" />
          </audio>
          <div>
            <Text
              marginBottom="5"
              fontSize="16"
              fontWeight={800}
              color="primary"
            >
              {properties.title}
            </Text>
            <s.Live>
              <p>
                {properties.description}
              </p>
            </s.Live>
          </div>
        </s.Current>
        <s.Controls ref={buttonRef} onClick={handleClick}>
          {toggleAudioLive ?
            <PauseSVG /> : <PlaySVG />
          }
        </s.Controls>
      </s.Content>
    </s.Container>
  );
}
