import React from "react";
import { useSelector } from "react-redux"

// import { Container } from './styles';

function SearchSVG({ color }) {
  const { colors } = useSelector(state => state.config)
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.341 10.6335L15.831 15.1235L15.124 15.8305L10.634 11.3405L11.341 10.6335Z"
        fill={fill}
      />
      <path
        d="M6.5 13C2.9 13 0 10.1 0 6.5C0 2.9 2.9 0 6.5 0C10.1 0 13 2.9 13 6.5C13 10.1 10.1 13 6.5 13ZM6.5 1C3.45 1 1 3.45 1 6.5C1 9.55 3.45 12 6.5 12C9.55 12 12 9.55 12 6.5C12 3.45 9.55 1 6.5 1Z"
        fill={fill}
      />
    </svg>
  );
}

export default SearchSVG;
