import React from "react";
import { useSelector } from "react-redux";

// import { Container } from './styles';

function StarSVG({ color }) {
  const { colors } = useSelector((state) => state.config);
  const fill = colors[color] || color || colors.secondary;
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2887 17.8378C3.83037 18.0729 3.3103 17.6608 3.40291 17.1348L4.38844 11.5185L0.205284 7.53362C-0.185366 7.16078 0.0176767 6.47922 0.541314 6.4056L6.35713 5.57918L8.95038 0.441365C9.18429 -0.0217149 9.81717 -0.0217149 10.0511 0.441365L12.6443 5.57918L18.4602 6.4056C18.9838 6.47922 19.1868 7.16078 18.7962 7.53362L14.613 11.5185L15.5986 17.1348C15.6912 17.6608 15.1711 18.0729 14.7128 17.8378L9.49895 15.159L4.28752 17.8378H4.2887Z"
        fill={fill}
      />
    </svg>
  );
}

export default StarSVG;
