import styled, { css } from "styled-components";
import { colors, device, animations, config } from "../../../styles";

export const Container = styled.div`
  background: ${colors.alabaster};
  height: ${config.miniPlayerHeight};
  padding: 12px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1;
  bottom: calc(${config.tabBarHeight} + 1px);
  left: 0;
  right: 0;
  box-shadow: 0px -3px 10px rgba(176, 173, 173, 0.29),
    0px 1px 0px ${colors.gallery};
  animation: ${css`0.2s ${animations.slideInUpAnimation}`};

  @media ${device.fromMobileL} {
    max-width: ${device.size.mobileL};
    margin: 0 auto;
  }

  audio {
    display: none;
  }

  ${({ isNotLive }) => isNotLive && 'display: none'};
`;

export const ProgressSliderTop = styled.div`
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;

  & > .rc-slider {
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${device.size.laptop};
`;

export const Current = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 50px;
    height: 50px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
`;

export const Controls = styled.div`
  cursor: pointer;
`

export const Live = styled.div`
  background: #fff;
  padding: 3px 7px;
  padding-left: 20px;
  border-radius: 5px;
  position: relative;
  margin-left: -4px;

  &:after {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    position: absolute;
    background: red;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    box-shadow: 0px 0px 4px #FE483E;
  }

  p {
    color: #FE483E;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const Picture = styled.picture`
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.08);
  border-radius: 5px;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;

  img {
    max-width: 100%;
    height: auto;
  }
`;
