/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      nickName
      genre
      jobTitle
      companyID
       teamName
      lastLogin
      filialID
      email
      active
      profilePictureUrl
      createdAt
      updatedAt
      cognitoGroup { 
        name 
        company {
            id
            name
            companyConfigs { 
                items {
                  primaryColor
                  secondaryColor
                  logoUrl
                  coverUrl
                }
            }
        } 
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      nickName
      genre
      jobTitle
      companyID
       teamName
      lastLogin
      filialID
      email
      active
      profilePictureUrl
      createdAt
      updatedAt
      cognitoGroup { 
        name 
        company {
            id
            name
            companyConfigs { 
                items {
                  primaryColor
                  secondaryColor
                  logoUrl
                  coverUrl
                }
            }
        } 
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      nickName
      genre
      jobTitle
      companyID
      company {
        id
        cognitoPoolID
        cognitoGroups {
          nextToken
        }
        companyConfigs {
          nextToken
        }
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
      }
      cognitoPoolID
      cognitoGroupName
      cognitoGroupID
      cognitoGroup {
        id
        cognitoPoolID
        companyID
        company {
          id
          cognitoPoolID
          ARNCognitoPool
          name
          loginMode
          mailDomain
          federation
          active
          createdAt
          updatedAt
        }
        name
        audioTags
        createdAt
        updatedAt
      }
      teamName
      lastLogin
      filialID
      email
      active
      profilePictureUrl
      createdAt
      updatedAt
    }
  }
`;
export const createCognitoGroup = /* GraphQL */ `
  mutation CreateCognitoGroup(
    $input: CreateCognitoGroupInput!
    $condition: ModelcognitoGroupConditionInput
  ) {
    createCognitoGroup(input: $input, condition: $condition) {
      id
      cognitoPoolID
      companyID
      company {
        id
        cognitoPoolID
        cognitoGroups {
          nextToken
        }
        companyConfigs {
          nextToken
        }
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
      }
      name
      audioTags
      createdAt
      updatedAt
    }
  }
`;
export const updateCognitoGroup = /* GraphQL */ `
  mutation UpdateCognitoGroup(
    $input: UpdateCognitoGroupInput!
    $condition: ModelcognitoGroupConditionInput
  ) {
    updateCognitoGroup(input: $input, condition: $condition) {
      id
      cognitoPoolID
      companyID
      company {
        id
        cognitoPoolID
        cognitoGroups {
          nextToken
        }
        companyConfigs {
          nextToken
        }
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
      }
      name
      audioTags
      createdAt
      updatedAt
    }
  }
`;
export const deleteCognitoGroup = /* GraphQL */ `
  mutation DeleteCognitoGroup(
    $input: DeleteCognitoGroupInput!
    $condition: ModelcognitoGroupConditionInput
  ) {
    deleteCognitoGroup(input: $input, condition: $condition) {
      id
      cognitoPoolID
      companyID
      company {
        id
        cognitoPoolID
        cognitoGroups {
          nextToken
        }
        companyConfigs {
          nextToken
        }
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
      }
      name
      audioTags
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      cognitoPoolID
      cognitoGroups {
        items {
          id
          cognitoPoolID
          companyID
          name
          audioTags
          createdAt
          updatedAt
        }
        nextToken
      }
      companyConfigs {
        items {
          id
          companyID
          primaryColor
          secondaryColor
          coverUrl
          logoUrl
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      ARNCognitoPool
      name
      loginMode
      mailDomain
      federation
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      cognitoPoolID
      cognitoGroups {
        items {
          id
          cognitoPoolID
          companyID
          name
          audioTags
          createdAt
          updatedAt
        }
        nextToken
      }
      companyConfigs {
        items {
          id
          companyID
          primaryColor
          secondaryColor
          coverUrl
          logoUrl
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      ARNCognitoPool
      name
      loginMode
      mailDomain
      federation
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      cognitoPoolID
      cognitoGroups {
        items {
          id
          cognitoPoolID
          companyID
          name
          audioTags
          createdAt
          updatedAt
        }
        nextToken
      }
      companyConfigs {
        items {
          id
          companyID
          primaryColor
          secondaryColor
          coverUrl
          logoUrl
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      ARNCognitoPool
      name
      loginMode
      mailDomain
      federation
      active
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyConfig = /* GraphQL */ `
  mutation CreateCompanyConfig(
    $input: CreateCompanyConfigInput!
    $condition: ModelCompanyConfigConditionInput
  ) {
    createCompanyConfig(input: $input, condition: $condition) {
      id
      companyID
      primaryColor
      secondaryColor
      coverUrl
      logoUrl
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyConfig = /* GraphQL */ `
  mutation UpdateCompanyConfig(
    $input: UpdateCompanyConfigInput!
    $condition: ModelCompanyConfigConditionInput
  ) {
    updateCompanyConfig(input: $input, condition: $condition) {
      id
      companyID
      primaryColor
      secondaryColor
      coverUrl
      logoUrl
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyConfig = /* GraphQL */ `
  mutation DeleteCompanyConfig(
    $input: DeleteCompanyConfigInput!
    $condition: ModelCompanyConfigConditionInput
  ) {
    deleteCompanyConfig(input: $input, condition: $condition) {
      id
      companyID
      primaryColor
      secondaryColor
      coverUrl
      logoUrl
      active
      createdAt
      updatedAt
    }
  }
`;
export const createCustomGroup = /* GraphQL */ `
  mutation CreateCustomGroup(
    $input: CreateCustomGroupInput!
    $condition: ModelcustomGroupConditionInput
  ) {
    createCustomGroup(input: $input, condition: $condition) {
      id
      name
      cognitoPoolID
      tags
      jobTitle
      filialID
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomGroup = /* GraphQL */ `
  mutation UpdateCustomGroup(
    $input: UpdateCustomGroupInput!
    $condition: ModelcustomGroupConditionInput
  ) {
    updateCustomGroup(input: $input, condition: $condition) {
      id
      name
      cognitoPoolID
      tags
      jobTitle
      filialID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomGroup = /* GraphQL */ `
  mutation DeleteCustomGroup(
    $input: DeleteCustomGroupInput!
    $condition: ModelcustomGroupConditionInput
  ) {
    deleteCustomGroup(input: $input, condition: $condition) {
      id
      name
      cognitoPoolID
      tags
      jobTitle
      filialID
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      companyID
      title
      key
      tags
      ownerArea
      public
      expireDate
      url
      bucketPath
      coverUrl
      active
      totalTime
      plays {
        items {
          id
          userid
          fileid
          playlistid
          completed
          timePlay
          createdAt
          updatedAt
        }
        nextToken
      }
      bookmarks {
        items {
          id
          userid
          fileid
          playlistid
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      companyID
      title
      key
      tags
      ownerArea
      public
      expireDate
      url
      bucketPath
      coverUrl
      active
      totalTime
      plays {
        items {
          id
          userid
          fileid
          playlistid
          completed
          timePlay
          createdAt
          updatedAt
        }
        nextToken
      }
      bookmarks {
        items {
          id
          userid
          fileid
          playlistid
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      companyID
      title
      key
      tags
      ownerArea
      public
      expireDate
      url
      bucketPath
      coverUrl
      active
      totalTime
      plays {
        items {
          id
          userid
          fileid
          playlistid
          completed
          timePlay
          createdAt
          updatedAt
        }
        nextToken
      }
      bookmarks {
        items {
          id
          userid
          fileid
          playlistid
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBookmark = /* GraphQL */ `
  mutation CreateBookmark(
    $input: CreateBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    createBookmark(input: $input, condition: $condition) {
      id
      userid
      fileid
      playlistid
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateBookmark = /* GraphQL */ `
  mutation UpdateBookmark(
    $input: UpdateBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    updateBookmark(input: $input, condition: $condition) {
      id
      userid
      fileid
      playlistid
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookmark = /* GraphQL */ `
  mutation DeleteBookmark(
    $input: DeleteBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    deleteBookmark(input: $input, condition: $condition) {
      id
      userid
      fileid
      file {
        id
        companyID
        title
        key
        tags
        ownerArea
        public
        expireDate
        url
        bucketPath
        coverUrl
        active
        totalTime
        plays {
          nextToken
        }
        bookmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      playlistid
      playlist {
        id
        companyID
        cognitoGroupID
        name
        desc
        ownerArea
        createdBy
        published
        startdate
        enddate
        schedule
        type
        tag
        coverUrl
        active
        filialID
        playlistfiles {
          nextToken
        }
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const createPlay = /* GraphQL */ `
  mutation CreatePlay(
    $input: CreatePlayInput!
    $condition: ModelPlayConditionInput
  ) {
    createPlay(input: $input, condition: $condition) {
      id
      userid
      fileid
      playlistid
      completed
      timePlay
      createdAt
      updatedAt
    }
  }
`;
export const updatePlay = /* GraphQL */ `
  mutation UpdatePlay(
    $input: UpdatePlayInput!
    $condition: ModelPlayConditionInput
  ) {
    updatePlay(input: $input, condition: $condition) {
      id
      userid
      fileid
      playlistid
      completed
      timePlay
      createdAt
      updatedAt
    }
  }
`;
export const deletePlay = /* GraphQL */ `
  mutation DeletePlay(
    $input: DeletePlayInput!
    $condition: ModelPlayConditionInput
  ) {
    deletePlay(input: $input, condition: $condition) {
      id
      userid
      fileid
      playlistid
      completed
      timePlay
      createdAt
      updatedAt
    }
  }
`;
export const createPlaylist = /* GraphQL */ `
  mutation CreatePlaylist(
    $input: CreatePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    createPlaylist(input: $input, condition: $condition) {
      id
      companyID
      cognitoGroupID
      name
      desc
      ownerArea
      createdBy
      published
      startdate
      enddate
      schedule
      type
      tag
      coverUrl
      active
      filialID
      playlistfiles {
        items {
          id
          orderPlaylist
          fileid
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePlaylist = /* GraphQL */ `
  mutation UpdatePlaylist(
    $input: UpdatePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    updatePlaylist(input: $input, condition: $condition) {
      id
      companyID
      cognitoGroupID
      name
      desc
      ownerArea
      createdBy
      published
      startdate
      enddate
      schedule
      type
      tag
      coverUrl
      active
      filialID
      playlistfiles {
        items {
          id
          orderPlaylist
          fileid
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePlaylist = /* GraphQL */ `
  mutation DeletePlaylist(
    $input: DeletePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    deletePlaylist(input: $input, condition: $condition) {
      id
      companyID
      cognitoGroupID
      name
      desc
      ownerArea
      createdBy
      published
      startdate
      enddate
      schedule
      type
      tag
      coverUrl
      active
      filialID
      playlistfiles {
        items {
          id
          orderPlaylist
          fileid
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPlaylistFile = /* GraphQL */ `
  mutation CreatePlaylistFile(
    $input: CreatePlaylistFileInput!
    $condition: ModelPlaylistFileConditionInput
  ) {
    createPlaylistFile(input: $input, condition: $condition) {
      id
      orderPlaylist
      fileid
      file {
        id
        companyID
        title
        key
        tags
        ownerArea
        public
        expireDate
        url
        bucketPath
        coverUrl
        active
        totalTime
        plays {
          nextToken
        }
        bookmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      playlistID
      createdAt
      updatedAt
    }
  }
`;
export const updatePlaylistFile = /* GraphQL */ `
  mutation UpdatePlaylistFile(
    $input: UpdatePlaylistFileInput!
    $condition: ModelPlaylistFileConditionInput
  ) {
    updatePlaylistFile(input: $input, condition: $condition) {
      id
      orderPlaylist
      fileid
      file {
        id
        companyID
        title
        key
        tags
        ownerArea
        public
        expireDate
        url
        bucketPath
        coverUrl
        active
        totalTime
        plays {
          nextToken
        }
        bookmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      playlistID
      createdAt
      updatedAt
    }
  }
`;
export const deletePlaylistFile = /* GraphQL */ `
  mutation DeletePlaylistFile(
    $input: DeletePlaylistFileInput!
    $condition: ModelPlaylistFileConditionInput
  ) {
    deletePlaylistFile(input: $input, condition: $condition) {
      id
      orderPlaylist
      fileid
      file {
        id
        companyID
        title
        key
        tags
        ownerArea
        public
        expireDate
        url
        bucketPath
        coverUrl
        active
        totalTime
        plays {
          nextToken
        }
        bookmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      playlistID
      createdAt
      updatedAt
    }
  }
`;
export const createGlobalConfigs = /* GraphQL */ `
  mutation CreateGlobalConfigs(
    $input: CreateGlobalConfigsInput!
    $condition: ModelGlobalConfigsConditionInput
  ) {
    createGlobalConfigs(input: $input, condition: $condition) {
      id
      audioCompletedParam
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateGlobalConfigs = /* GraphQL */ `
  mutation UpdateGlobalConfigs(
    $input: UpdateGlobalConfigsInput!
    $condition: ModelGlobalConfigsConditionInput
  ) {
    updateGlobalConfigs(input: $input, condition: $condition) {
      id
      audioCompletedParam
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlobalConfigs = /* GraphQL */ `
  mutation DeleteGlobalConfigs(
    $input: DeleteGlobalConfigsInput!
    $condition: ModelGlobalConfigsConditionInput
  ) {
    deleteGlobalConfigs(input: $input, condition: $condition) {
      id
      audioCompletedParam
      active
      createdAt
      updatedAt
    }
  }
`;
export const createFilial = /* GraphQL */ `
  mutation CreateFilial(
    $input: CreateFilialInput!
    $condition: ModelFilialConditionInput
  ) {
    createFilial(input: $input, condition: $condition) {
      id
      companyID
      name
      desc
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateFilial = /* GraphQL */ `
  mutation UpdateFilial(
    $input: UpdateFilialInput!
    $condition: ModelFilialConditionInput
  ) {
    updateFilial(input: $input, condition: $condition) {
      id
      companyID
      name
      desc
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteFilial = /* GraphQL */ `
  mutation DeleteFilial(
    $input: DeleteFilialInput!
    $condition: ModelFilialConditionInput
  ) {
    deleteFilial(input: $input, condition: $condition) {
      id
      companyID
      name
      desc
      active
      createdAt
      updatedAt
    }
  }
`;
