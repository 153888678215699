import styled from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
  margin-top: 10px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    width: 100%;
  }
`;

export const Card = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  max-width: 345px;
  width: 100%;
  height: ${({ height }) => height || '100px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  &.radio {
    flex-direction: column;
    height: auto;
    padding: 20px;
    width: 100%;
  }
`;

export const Img = styled.img`
  width: ${({ width }) => width || 70}px;
  height: ${({ height }) => height || 70}px;
  border-radius: 5px;
  margin-right: 5px;
`;


//TODO: alterar as cores para que sejam do company config
export const Live = styled.div`
  background: #fff;
  padding: 3px 7px;
  padding-left: 20px;
  border-radius: 5px;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    position: absolute;
    background: red;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    box-shadow: 0px 0px 4px #FE483E;
  }

  p {
    color: #FE483E;
    font-size: 12px;
    font-weight: bold;
  }
`

export const RadioLive = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #0272EC;
  padding: 10px 16px;
  box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.25);

  audio {
    display: none;
  }
`

export const Button = styled.button`
  background: #0560C4;
  border: 1px solid #0351A6;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
`

export const Row = styled.div`
  align-items: center;
  display: flex;
  width: 50%;

  h2 {
    color: #fff;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    font-weight: normal;
    padding-left: 14px;
  }

  &:last-of-type {
    justify-content: flex-end;
  }
`
