import styled, { css } from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
  padding-left: 10px;
  width: 100%;
`;

export const List = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100vw;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  ::-webkit-scrollbar {
    width: 10px;

    ${({showScrollbar}) => !showScrollbar && 'display: none;'}
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid #eeeeee;
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Item = styled.a`
  &:link {
    text-decoration: inherit;
    color: inherit;
  }

  &:visited {
    text-decoration: inherit;
    color: inherit;
  }

  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  border-radius: 10px;
  ${({ src }) =>
    src
      ? css`
          background-image: linear-gradient(
              360deg,
              rgba(45, 45, 45, 0.8) 0%,
              rgba(45, 45, 45, 0.8) 100%
            ),
            url(${({ src }) => src});
        `
      : css`
          background-color: ${colors.white};
        `};
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 200px;
  height: 200px;
`;

export const ItemBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AudioDetails = styled.div`
  display: flex;
  flex-direction: column;
`;
