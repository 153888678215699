import styled from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  background: ${({ background }) => colors[background] || colors.primary};
  z-index: 1;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 15px;
  background: ${colors.transparent};
  border: none;
  z-index: 1;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
`;

export const Banner = styled.div`
  position: absolute;
  z-index: -1;
  opacity: 0.5;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colors.white};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  align-items: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.12);
  position: relative;
`;
