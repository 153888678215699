import styled from "styled-components";

export const ModalHeader = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const ModalBody = styled.div`
  width: 100%;
  max-width: 300px;
  margin: auto;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;
