import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from 'aws-amplify';
import i from "../../assets";

import { Creators as AuthActions } from "../../store/ducks/auth";
import { getPublicCompaniesConfigs } from "../../services/amplify/auth";

import Button from "../../components/Button";
import Text from "../../components/Text";
import ShastaLibrary, { View } from "../../components/ShastaLibrary";
import Splash from "../../components/Splash";

import { routes } from "../../routes";

import ModalLogin from "./ModalLogin";

import * as s from "./styles";

function SignIn({ history }) {
  const dispatch = useDispatch();
  const [ companyDomain ] = window.location.host.split('.');

  const { isLoading, isOut, error, emailReceived } = useSelector(
    (state) => state.auth.signInState
  );

  const [modalLoginVisible, setModalLoginVisible] = useState(!!emailReceived);

  function loginWithProvider() {
    const configureAmplifyByCompany = company => {
      const configuration = {
        'radioluizadigital': 'slmagazineopenid',
        'library': 'openidmagalu',
        'dev': 'login-magalu'
      }

      return configuration[company] ? configuration[company] : ''
    }

    console.log('configureAmplifyByCompany >> loginWithProvider >> SignIn Page', configureAmplifyByCompany(companyDomain));

    Auth.federatedSignIn({ provider: configureAmplifyByCompany(companyDomain) })
  }

  async function getDomain(email) {
    await setModalLoginVisible(false);

    await dispatch(AuthActions.setSignInState({ isOut: true }));

    setTimeout(async () => {
      const domain = `@${email.split('@').pop()}`;

      console.log('companyDomain', companyDomain);

      try {
        const { amplifyAppUrl } = await getPublicCompaniesConfigs(domain) || {};
        console.log('amplifyAppUrl', amplifyAppUrl);
        if (!amplifyAppUrl) {
          throw new Error('');
        } else {
          if (companyDomain === 'localhost:3000') {
            history.push(`/redirect/${email}`);
          } else {
            window.location.replace(`${amplifyAppUrl.replace(/\/$/, '')}/redirect/${email}`)
          }
        }
      } catch (error) {
        await dispatch(AuthActions.setSignInState({ isOut: false, error: 'Ops! Ocorreu um problema' }));
        setModalLoginVisible(true)
      }
    }, 500);
  }

  async function signIn({ email, password }) {
    await setModalLoginVisible(false);

    await dispatch(AuthActions.setSignInState({ isOut: true }));

    setTimeout(async () => {
      dispatch(
        AuthActions.signInRequest({
          email,
          password,
          onSuccess: () => history.push(routes.home),
          onError: () => setModalLoginVisible(true),
        })
      );
    }, 200);
  }

  if (isLoading) {
    return <Splash />;
  }

  return (
    <>
      <View>
        <s.HalfView justify="flex-start">
          <ShastaLibrary animation={isOut ? "slideOutDown" : "slideInUp"} />
        </s.HalfView>
        <s.HalfView
          background="gallery"
          animation={isOut ? "slideOutDown" : "slideInUp"}
        >
          <s.WrapperButton>
            { companyDomain === 'radioluizadigital' ?
              <Button
                height="90"
                background="white"
                border="shastaSecondary"
                onClick={loginWithProvider}
              >
                <Text fontSize="24" fontWeight="800">
                  ENTRAR COM MAGALU
                </Text>
              </Button>
              : null
            }
            <Button
              height="90"
              background="white"
              border="shastaSecondary"
              onClick={() => setModalLoginVisible(true)}
            >
              <Text fontSize="24" fontWeight="800">
                ENTRAR COM E-MAIL
              </Text>
            </Button>
          </s.WrapperButton>
          <s.ImgMountain src={i.imgMountain} />
        </s.HalfView>
      </View>
      <ModalLogin
        emailReceived={emailReceived}
        getDomain={getDomain}
        signIn={signIn}
        error={error}
        isOpen={modalLoginVisible}
        close={() => setModalLoginVisible(false)}
      />
    </>
  );
}

export default SignIn;
