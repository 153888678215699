import styled, { css } from "styled-components";
import { colors, animations, device } from "../../styles";

export const View = styled.div`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    ${colors.shastaSecondary} 0%,
    ${colors.mineShaft} 100%
  );
  position: relative;
  padding-top: 3%;

  ${({ isSplash }) =>
    isSplash &&
    css`
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 100vh;
      overflow: hidden;
    `}
`;

const containerAlignment = {
  middle: css`
    position: absolute;
    top: 30vh;
  `,
};

export const shastaAnimations = {
  pulse: css`
    animation: 1.25s ${animations.pulseAnimation} infinite;
  `,
  slideInUp: css`
    animation: 1s ${animations.slideInUpAnimation} forwards;
  `,
  slideOutDown: css`
    animation: 1s ${animations.slideOutDownAnimation} forwards;
  `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ align }) => align && containerAlignment[align]}

  ${({ animation }) => animation && shastaAnimations[animation]}

  & > svg {
    width: 30px;
  }

  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  & > :first-child {
    margin-bottom: 5px;
  }
`;

const mountainHeight = 250;

const alignment = {
  default: css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  middle: css`
    top: calc(50% - 85px);
    height: 190px;
    @media ${device.fromMobileM} {
      top: calc(50% - ${mountainHeight / 2}px);
      height: ${mountainHeight}px;
    }
  `,
  bottom: css`
    bottom: -85px;
  `,
};

export const ImgMountain = styled.div`
  width: 100%;
  height: ${mountainHeight}px;
  position: absolute;
  z-index: 1;
  background-image: url(${({ src }) => src});
  background-position-x: 25%;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ align }) => alignment[align || "middle"]}

  ${({ animation }) => animation && shastaAnimations[animation]}
`;
