export const Types = {
  SIGN_IN_REQUEST: "auth/SIGN_IN_REQUEST",
  SET_UP_REQUEST: "auth/SET_UP_REQUEST",
  SET_COGNITO_USER: "auth/SET_COGNITO_USER",
  SET_SIGN_IN_STATE: "auth/SET_SIGN_IN_STATE",
  SET_SET_UP_STATE: "auth/SET_SET_UP_STATE",
  SET_COMPANY: "auth/SET_COMPANY",
  CHECK_FEDERATED_SIGNIN: "auth/CHECK_FEDERATED_SIGNIN",
};

const INITIAL_STATE = {
  cognitoUser: null,
  cognitoSession: null,
  attributes: {},
  company: {},
  signInState: {
    emailReceived: null, // 'gregory@shasta.com',
    error: "",
    isOut: false,
    isLoading: false,
  },
  setUpState: {
    isLoading: true,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_SIGN_IN_STATE:
      return {
        ...state,
        signInState: { ...state.signInState, ...action.payload },
      };
    case Types.SIGN_IN_REQUEST:
      return {
        ...state,
        signInState: { ...state.signInState, isLoading: true },
      };
    case Types.SET_SET_UP_STATE:
      return {
        ...state,
        setUpState: { ...state.setUpState, ...action.payload },
      };
    case Types.SET_COGNITO_USER:
      const { cognitoUser, shastaUser = {}, company } = action.payload || {};
      const { Session: cognitoSession, attributes = {} } = cognitoUser || {};
      Object.assign(attributes, shastaUser);
      return {
        ...state,
        cognitoUser,
        cognitoSession,
        attributes,
        company,
      };
    case Types.SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    default:
      return state;
  }
}

export const Creators = {
  signInRequest: (payload) => ({ type: Types.SIGN_IN_REQUEST, payload }),
  setUpRequest: (payload) => ({ type: Types.SET_UP_REQUEST, payload }),
  setCognitoUser: (payload) => ({ type: Types.SET_COGNITO_USER, payload }),
  setSignInState: (payload) => ({ type: Types.SET_SIGN_IN_STATE, payload }),
  setSetUpState: (payload) => ({ type: Types.SET_SET_UP_STATE, payload }),
  setCompany: (payload) => ({ type: Types.SET_COMPANY, payload }),
  checkFederatedSignIn: (payload) => ({ type: Types.CHECK_FEDERATED_SIGNIN, payload }),
};
