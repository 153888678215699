import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { Creators as PlayerActions } from "../../store/ducks/player";

import { getPlaylistAttributes } from "../../utils";

import { Block } from "../../styles/components";

import Text from "../Text";
import { ProgressSlider, ControlButton } from "../Player";
import Bookmark from "../Bookmark";

import {
  getProgress,
  msToTime,
  getNumberOfAudiosLabel,
  getTotalTimeLabel,
} from "../../utils";

import * as s from "./styles";

export default function AudioList({ playlistDetails, audios, loading }) {
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);
  const { colors } = useSelector((state) => state.config);

  function fileIsInPlayer(f) {
    return player.currentSong && player.currentSong.id === f.id;
  }

  async function setSong(song) {
    if (!fileIsInPlayer(song)) {
      const songsList = audios.map((a) => ({
        ...a,
        ...getPlaylistAttributes(playlistDetails),
      }));
      dispatch(PlayerActions.loadSong(song, songsList));
    }
  }

  return (
    <s.Container>
      <s.ListHeader>
        <Text color="primary" fontSize="18" fontWeight={800}>
          {loading ? (
            <Skeleton width={100} height={20} />
          ) : (
            getNumberOfAudiosLabel(audios)
          )}
        </Text>
        <Text fontSize="18" fontWeight={300}>
          {loading ? (
            <Skeleton width={100} height={20} />
          ) : (
            getTotalTimeLabel(audios)
          )}
        </Text>
      </s.ListHeader>
      <s.List>
        {audios.map((f, i) => {
          if (loading) {
            return (
              <s.Item key={f.id}>
                <Skeleton width="100%" height={60} />
              </s.Item>
            );
          }

          const isInPlayer = fileIsInPlayer(f);

          const timePlay = isInPlayer ? player.position : f.timePlay;
          const duration = isInPlayer ? player.duration : f.totalTime;

          const showTimePlay = timePlay > 0;

          const file = {
            ...f,
            timePlay,
            progress: getProgress({
              position: timePlay,
              duration,
            }),
            status:
              isInPlayer && player.status === "PLAYING" ? "pause" : "play",
          };

          return (
            <s.Item key={file.id}>
              <Block centered>
                <ControlButton
                  onClick={() =>
                    setSong({
                      ...file,
                      ...getPlaylistAttributes(playlistDetails),
                    })
                  }
                  withBorder
                  color="primary"
                  size="xlarge"
                  type={file.status}
                />
              </Block>
              <Block isColumn flex={1} maxWidth="65%">
                <Block minWidth="50%" marginBottom="5" alignItemsCenter>
                  <Text marginRight="10" fontSize="16" fontWeight={800}>
                    #{i + 1}
                  </Text>
                  <Text fontSize="16" fontWeight={300}>
                    {file.title}
                  </Text>
                </Block>
                <Block alignItemsCenter>
                  <Text color={showTimePlay ? "" : "silver"} marginRight="10" fontSize="12" fontWeight={800}>
                    {msToTime(showTimePlay ? file.timePlay : file.totalTime)}
                  </Text>
                  <ProgressSlider
                    progress={file.progress}
                    song={isInPlayer ? file : null}
                    railColor={colors.mercury}
                    trackColor={colors.primary}
                  />
                </Block>
              </Block>
              <Block centered>
                <Bookmark
                  file={{ ...file, ...getPlaylistAttributes(playlistDetails) }}
                />
              </Block>
            </s.Item>
          );
        })}
      </s.List>
    </s.Container>
  );
}
