/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomGroup = /* GraphQL */ `
  query GetCustomGroup($id: ID!) {
    getCustomGroup(id: $id) {
      id
      name
      cognitoPoolID
      tags
      jobTitle
      filialID
      createdAt
      updatedAt
    }
  }
`;
export const listCustomGroups = /* GraphQL */ `
  query ListCustomGroups(
    $filter: ModelcustomGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        cognitoPoolID
        tags
        jobTitle
        filialID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      companyID
      title
      key
      tags
      ownerArea
      public
      expireDate
      url
      bucketPath
      coverUrl
      active
      totalTime
      plays {
        items {
          id
          userid
          fileid
          playlistid
          completed
          timePlay
          createdAt
          updatedAt
        }
        nextToken
      }
      bookmarks {
        items {
          id
          userid
          fileid
          playlistid
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        title
        key
        tags
        ownerArea
        public
        expireDate
        url
        bucketPath
        coverUrl
        active
        totalTime
        plays {
          nextToken
        }
        bookmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookmark = /* GraphQL */ `
  query GetBookmark($id: ID!) {
    getBookmark(id: $id) {
      id
      userid
      fileid
      file {
        id
        companyID
        title
        key
        tags
        ownerArea
        public
        expireDate
        url
        bucketPath
        coverUrl
        active
        totalTime
        plays {
          nextToken
        }
        bookmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      playlistid
      playlist {
        id
        playlistTypeID
        companyID
        cognitoGroupID
        name
        desc
        ownerArea
        createdBy
        published
        startdate
        enddate
        schedule
        type
        tag
        coverUrl
        active
        filialID
        playlistfiles {
          nextToken
        }
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const listBookmarks = /* GraphQL */ `
  query ListBookmarks(
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userid
        fileid
        file {
          id
          companyID
          title
          key
          tags
          ownerArea
          public
          expireDate
          url
          bucketPath
          coverUrl
          active
          totalTime
          createdAt
          updatedAt
        }
        playlistid
        playlist {
          id
          playlistTypeID
          companyID
          cognitoGroupID
          name
          desc
          ownerArea
          createdBy
          published
          startdate
          enddate
          schedule
          type
          tag
          coverUrl
          active
          filialID
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlay = /* GraphQL */ `
  query GetPlay($id: ID!) {
    getPlay(id: $id) {
      id
      userid
      fileid
      playlistid
      completed
      timePlay
      createdAt
      updatedAt
    }
  }
`;
export const listPlays = /* GraphQL */ `
  query ListPlays(
    $filter: ModelPlayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userid
        fileid
        playlistid
        completed
        timePlay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaylist = /* GraphQL */ `
  query GetPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      playlistTypeID
      companyID
      cognitoGroupID
      name
      desc
      ownerArea
      createdBy
      published
      startdate
      enddate
      schedule
      type
      tag
      coverUrl
      active
      filialID
      playlistfiles {
        items {
          id
          orderPlaylist
          fileid
          playlistID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPlaylists = /* GraphQL */ `
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playlistTypeID
        companyID
        cognitoGroupID
        name
        desc
        ownerArea
        createdBy
        published
        startdate
        enddate
        schedule
        type
        tag
        coverUrl
        active
        filialID
        playlistfiles {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaylistType = /* GraphQL */ `
  query GetPlaylistType($id: ID!) {
    getPlaylistType(id: $id) {
      id
      playlistTypeDesc
      playlistTypeObs
      createdAt
      updatedAt
    }
  }
`;
export const listPlaylistTypes = /* GraphQL */ `
  query ListPlaylistTypes(
    $filter: ModelPlaylistTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylistTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playlistTypeDesc
        playlistTypeObs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaylistFile = /* GraphQL */ `
  query GetPlaylistFile($id: ID!) {
    getPlaylistFile(id: $id) {
      id
      orderPlaylist
      fileid
      file {
        id
        companyID
        title
        key
        tags
        ownerArea
        public
        expireDate
        url
        bucketPath
        coverUrl
        active
        totalTime
        plays {
          nextToken
        }
        bookmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      playlistID
      createdAt
      updatedAt
    }
  }
`;
export const listPlaylistFiles = /* GraphQL */ `
  query ListPlaylistFiles(
    $filter: ModelPlaylistFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylistFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderPlaylist
        fileid
        file {
          id
          companyID
          title
          key
          tags
          ownerArea
          public
          expireDate
          url
          bucketPath
          coverUrl
          active
          totalTime
          createdAt
          updatedAt
        }
        playlistID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlobalConfigs = /* GraphQL */ `
  query GetGlobalConfigs($id: ID!) {
    getGlobalConfigs(id: $id) {
      id
      audioCompletedParam
      active
      createdAt
      updatedAt
    }
  }
`;
export const listGlobalConfigss = /* GraphQL */ `
  query ListGlobalConfigss(
    $filter: ModelGlobalConfigsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlobalConfigss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        audioCompletedParam
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFilial = /* GraphQL */ `
  query GetFilial($id: ID!) {
    getFilial(id: $id) {
      id
      companyID
      name
      desc
      active
      createdAt
      updatedAt
    }
  }
`;
export const listFilials = /* GraphQL */ `
  query ListFilials(
    $filter: ModelFilialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        name
        desc
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      nickName
      genre
      jobTitle
      companyID
      cognitoPoolID
      cognitoGroupName
      cognitoGroupID
      teamName
      lastLogin
      filialID
      email
      active
      profilePictureUrl
      obs
      createdAt
      updatedAt
      owner
      cognitoGroup {
        id
        cognitoPoolID
        companyID
        name
        audioTags
        createdAt
        updatedAt
        owner
        company {
          id
          cognitoPoolID
          ARNCognitoPool
          name
          loginMode
          mailDomain
          federation
          active
          createdAt
          updatedAt
          owner
        }
      }
      company {
        id
        cognitoPoolID
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
        cognitoGroups {
          nextToken
        }
        owner
        companyConfigs {
          nextToken
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nickName
        genre
        jobTitle
        companyID
        cognitoPoolID
        cognitoGroupName
        cognitoGroupID
        teamName
        lastLogin
        filialID
        email
        active
        profilePictureUrl
        obs
        createdAt
        updatedAt
        owner
        cognitoGroup {
          id
          cognitoPoolID
          companyID
          name
          audioTags
          createdAt
          updatedAt
          owner
        }
        company {
          id
          cognitoPoolID
          ARNCognitoPool
          name
          loginMode
          mailDomain
          federation
          active
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getUserAuth = /* GraphQL */ `
  query GetUserAuth($id: ID!) {
    getUserAuth(id: $id) {
      id
      emailLogin
      expireDate
      cdFilial
      companyID
      cognitoGroupID
      createdAt
      updatedAt
      owner
      company {
        id
        cognitoPoolID
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
        cognitoGroups {
          nextToken
        }
        owner
        companyConfigs {
          nextToken
        }
      }
    }
  }
`;
export const listUserAuths = /* GraphQL */ `
  query ListUserAuths(
    $filter: ModelUserAuthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAuths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailLogin
        expireDate
        cdFilial
        companyID
        cognitoGroupID
        createdAt
        updatedAt
        owner
        company {
          id
          cognitoPoolID
          ARNCognitoPool
          name
          loginMode
          mailDomain
          federation
          active
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getCognitoGroup = /* GraphQL */ `
  query GetCognitoGroup($id: ID!) {
    getCognitoGroup(id: $id) {
      id
      cognitoPoolID
      companyID
      name
      audioTags
      createdAt
      updatedAt
      owner
      company {
        id
        cognitoPoolID
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
        cognitoGroups {
          nextToken
        }
        owner
        companyConfigs {
          nextToken
        }
      }
    }
  }
`;
export const listCognitoGroups = /* GraphQL */ `
  query ListCognitoGroups(
    $filter: ModelcognitoGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCognitoGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoPoolID
        companyID
        name
        audioTags
        createdAt
        updatedAt
        owner
        company {
          id
          cognitoPoolID
          ARNCognitoPool
          name
          loginMode
          mailDomain
          federation
          active
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoPoolID
        ARNCognitoPool
        name
        loginMode
        mailDomain
        federation
        active
        createdAt
        updatedAt
        cognitoGroups {
          nextToken
        }
        owner
        companyConfigs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      cognitoPoolID
      ARNCognitoPool
      name
      loginMode
      mailDomain
      federation
      active
      createdAt
      updatedAt
      cognitoGroups {
        items {
          id
          cognitoPoolID
          companyID
          name
          audioTags
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      companyConfigs {
        items {
          id
          companyID
          primaryColor
          secondaryColor
          coverUrl
          logoUrl
          active
          amplifyAppUrl
          mailDomain
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const getCompanyConfig = /* GraphQL */ `
  query GetCompanyConfig($id: ID!) {
    getCompanyConfig(id: $id) {
      id
      companyID
      primaryColor
      secondaryColor
      coverUrl
      logoUrl
      active
      amplifyAppUrl
      mailDomain
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCompanyConfigs = /* GraphQL */ `
  query ListCompanyConfigs(
    $filter: ModelCompanyConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        primaryColor
        secondaryColor
        coverUrl
        logoUrl
        active
        amplifyAppUrl
        mailDomain
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
