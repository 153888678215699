import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { Creators as PlayerActions } from "../../store/ducks/player";
import { getProgress } from "../../utils";

import { Block } from "../../styles/components";

import Text from "../Text";
import { ProgressSlider } from "../Player";
import Bookmark from "../Bookmark";
import { StarSVG } from "../../assets";

import * as s from "./styles";

export default function AudioListMisc({ audios, withSkeleton, loading }) {
  const dispatch = useDispatch();
  const { colors } = useSelector((state) => state.config);
  const player = useSelector((state) => state.player);
  const { coverUrl } = useSelector(state => state.auth.company);

  const list =
    !audios.length && withSkeleton && loading ? new Array(10).fill({}) : audios;

  function fileIsInPlayer(f) {
    return player.currentSong && player.currentSong.id === f.id;
  }

  async function setSong(song) {
    if (!fileIsInPlayer(song)) {
      dispatch(PlayerActions.loadSong(song, audios));
    }
  }

  return (
    <s.Container>
      {!list.length && (
        <s.EmptyList>
          <StarSVG color="silver" />
          <Text fontSize="18">Nenhum item encontrado :(</Text>
        </s.EmptyList>
      )}
      <s.List>
        {list.map((a, i) => {
          if (loading) {
            return (
              <s.Item key={`my-list-${i}`}>
                <s.SkeletonWrapper>
                  <Skeleton width="100%" height={60} />
                  <Skeleton width="100%" height={60} />
                </s.SkeletonWrapper>
              </s.Item>
            );
          }

          const isInPlayer = fileIsInPlayer(a);

          const timePlay = isInPlayer ? player.position : a.timePlay;
          const file = {
            ...a,
            timePlay,
            progress: getProgress({
              position: timePlay,
              duration: a.totalTime,
            }),
          };

          return (
            <s.Item key={`list-audio-misc-${i}`}>
              <div>
                <Block centered onClick={() => setSong(file)}>
                  <s.Img src={file.thumbnail || coverUrl} alt="" />
                </Block>
                <Block isColumn flex={1} maxWidth="65%">
                  <Block
                    marginBottom="5"
                    alignItemsCenter
                    onClick={() => setSong(file)}
                  >
                    <Text marginRight="10" fontSize="16" fontWeight={800}>
                      {file.album}
                    </Text>
                  </Block>
                  <Block
                    marginBottom="5"
                    alignItemsCenter
                    onClick={() => setSong(file)}
                  >
                    <Text marginRight="10" fontSize="14" fontWeight={800}>
                      #{i + 1}
                    </Text>
                    <Text fontSize="14" fontWeight={300} limitText>
                      {file.title}
                    </Text>
                  </Block>
                  <Block alignItemsCenter>
                    <ProgressSlider
                      progress={file.progress}
                      song={isInPlayer ? file : null}
                      railColor={colors.mercury}
                      trackColor={colors.primary}
                    />
                  </Block>
                </Block>
                <Block zIndex={1} centered>
                  <Bookmark file={file} />
                </Block>
              </div>
            </s.Item>
          );
        })}
      </s.List>
    </s.Container>
  );
}
