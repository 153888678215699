import { keyframes } from "styled-components";
import { slideInUp, slideOutDown, pulse, bounceOutLeft } from "react-animations";

const slideInUpAnimation = keyframes`${slideInUp}`;
const slideOutDownAnimation = keyframes`${slideOutDown}`;
const pulseAnimation = keyframes`${pulse}`;
const bounceOutLeftAnimation = keyframes`${bounceOutLeft}`;

export default {
  slideInUpAnimation,
  slideOutDownAnimation,
  pulseAnimation,
  bounceOutLeftAnimation
}