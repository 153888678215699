import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export const PageHeader = styled.div`
  background: ${({ colors }) => colors.primary};
  height: ${({ headerHeight }) => headerHeight || 85}px;
  min-height: ${({ headerHeight }) => headerHeight || 85}px;
  max-height: ${({ headerHeight }) => headerHeight || 85}px;
`;

export const PageBody = styled.div`
  background: ${({ colors }) => colors.gallery};
  height: 100%;
`;

export const HeaderContent = styled.div`
  background-image: url(${({ src }) => src});
  background-position: 70% ${({ mountainVAlign }) => mountainVAlign || -20}%;
  background-repeat: no-repeat;
  background-size: 400px 175px;
  height: 100%;
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    width: 18px;
    height: 27px;
  }
`;
