import { API, graphqlOperation } from "aws-amplify";
import {
  listCognitoGroups,
  getCompany,
  listGlobalConfigss,
  listCompanyConfigs,
} from "../../graphql/queries2";

export async function apiGetCompanyConfig(cognitoPoolID, cognitoGroupName) {
  try {
    const [cognitoGroup] = await apiGetCognitoGroup(
      cognitoPoolID,
      cognitoGroupName
    );

    const [company, companyConfig] = await Promise.all([
      apiGetCompany(cognitoGroup.companyID),
      apiListCompanyConfigs(cognitoGroup.companyID),
    ]);

    return { ...companyConfig, ...company };
  } catch (error) {
    console.log("error in apiGetCompanyConfig >> ", error);
  }
}

export async function apiGetCognitoGroup(cognitoPoolID, cognitoGroupName) {
  try {
    const { data } = await API.graphql(
      graphqlOperation(listCognitoGroups, {
        filter: {
          cognitoPoolID: {
            eq: cognitoPoolID,
          },
          name: {
            eq: cognitoGroupName,
          },
        },
      })
    );
    return data.listCognitoGroups.items;
  } catch (error) {
    console.log("error in apiGetCognitoGroup >> ", error);
    return null;
  }
}

export async function apiGetCompany(id) {
  try {
    const { data } = await API.graphql(graphqlOperation(getCompany, { id }));
    return data.getCompany;
  } catch (error) {
    console.log("error in apiGetCompany >> ", error);
    return null;
  }
}

export async function apiListGlobalConfig() {
  try {
    const { data } = await API.graphql(graphqlOperation(listGlobalConfigss));
    return data.listGlobalConfigss.items[0];
  } catch (error) {
    console.log("error in apiListGlobalConfig >> ", error);
    return null;
  }
}

export async function apiListCompanyConfigs(id) {
  try {
    const { data } = await API.graphql(
      graphqlOperation(listCompanyConfigs, {
        filter: {
          companyid: {
            eq: id,
          },
        },
      })
    );
    return data.listCompanyConfigs.items[0];
  } catch (error) {
    console.log("error in apiGetCognitoGroup >> ", error);
    return null;
  }
}
