import React from "react";
import { useSelector } from "react-redux"
import Skeleton from "react-loading-skeleton";

import Text from "../Text";
import Badge from "../Badge";

import { getNumberOfAudiosLabel, getTotalTimeLabel } from "../../utils";
import { routes } from "../../routes";

import * as s from "./styles";

function AudioInfo({
  playlist = {},
  onClick,
  imgWidth,
  imgHeight,
  imgRadius,
  hasBadge,
  children,
  loading
}) {
  const { coverUrl } = useSelector(state => state.auth.company);
  return (
    <s.Container onClick={onClick}>
      {!loading && (playlist.coverUrl || coverUrl) ? (
        <s.Img
          width={imgWidth}
          height={imgHeight}
          radius={imgRadius}
          src={playlist.coverUrl || coverUrl}
          alt=""
        />
      ) : (
        <Skeleton width={imgWidth} height={imgHeight} />
      )}
      <s.AudioInfo>
        <Text fontSize="16" fontWeight={800} color="primary">
          {playlist.name || <Skeleton width="100%" height={30} />}
        </Text>
        <Text fontSize="12" fontWeight={300}>
          {playlist.desc || <Skeleton width="100%" height={60} />}
        </Text>
        <s.Description>
          {hasBadge && playlist.tag && (
            <Badge background="primary" size="xsmall">
              {playlist.tag}
            </Badge>
          )}
          {playlist.files && (
            <>
              <Text fontSize="12" fontWeight={800}>
                {getNumberOfAudiosLabel(playlist.files)} 
              </Text>
              <Text fontSize="11" fontWeight={300}>
                {getTotalTimeLabel(playlist.files)}
              </Text>
            </>
          )}
        </s.Description>
        {children}
      </s.AudioInfo>
    </s.Container>
  );
}

export default AudioInfo;
