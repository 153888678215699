import React, { useState } from "react";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Text from "../../../components/Text";
import Modal from "../../../components/Modal";

import * as s from "./styles";

function ModalLogin({ signIn, getDomain, emailReceived, error, ...props }) {
  const [fields, setFields] = useState({
    email: emailReceived,
    password: "",
  });

  function setFormFields({ target }) {
    const { name, value } = target;
    setFields({ ...fields, [name]: value });
  }

  const { email, password } = fields;

  const isFormValid = emailReceived ? email && password : !!email;

  function login(e) {
    e.preventDefault();

    console.log(email);

    if (!emailReceived) {
      getDomain(email);
    } else {
      signIn({ email, password });
    }
  }

  return (
    <Modal {...props}>
      <s.Form onSubmit={login}>
        <s.ModalHeader>
          <Text fontSize="20" fontWeight="800">
            ENTRAR
          </Text>
        </s.ModalHeader>

        <s.ModalBody>
          <Input
            label="E-mail"
            name="email"
            type="email"
            value={email}
            onChange={setFormFields}
          />
          {emailReceived && (
            <Input
              label="Senha"
              name="password"
              type="password"
              value={password}
              onChange={setFormFields}
            />
          )}
        </s.ModalBody>

        {error && (
          <Text
            width="100%"
            color="danger"
            fontSize="14"
            marginBottom="5"
            fontWeight="800"
          >
            {error}
          </Text>
        )}
        <Text
          width="100%"
          color={error ? "danger" : ""}
          fontSize="14"
          marginBottom="20"
        >
          Problemas para entrar? Contate seu gestor(a)
        </Text>
        <Button background="shastaSecondary" disabled={!isFormValid}>
          ENTRAR
        </Button>
      </s.Form>
    </Modal>
  );
}

export default ModalLogin;
