import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "react-router-dom";

import { Creators as AuthActions } from "../store/ducks/auth";

import Route, {
  PrivateRoute,
  UnauthenticatedRoute,
  ScrollToTopControlller,
} from "../components/Route";
import Splash from "../components/Splash";
import TabBar from "../components/TabBar";
import Player from "../components/Player";

import Home from "../pages/Home";
import Album from "../pages/Album";
import Library from "../pages/Library";
import MyList from "../pages/MyList";
import Profile from "../pages/Profile";
import SignIn from "../pages/SignIn";
import Redirect from "../pages/Redirect";
import RedirectFederatedSignIn from "../pages/RedirectFederatedSignIn";

import { Wrapper, Container, Content } from "../styles/components";

import routes from "./routes";

import { getCurrentUser } from '../services/amplify/auth';

export { routes };

function Routes() {
  const { isLoading } = useSelector((state) => state.auth.setUpState);
  const dispatch = useDispatch();
  const [isUserLogged, setIsUserLogged] = useState(false);

  useEffect(() => {
    dispatch(AuthActions.setUpRequest());
    const fetchUser = async () =>{
      const response = await getCurrentUser();
      console.log(response);
    }

    fetchUser()
  }, []);

  const cognitoSession = useSelector((state) => state.auth.cognitoSession);
  const cognitoUser = useSelector((state) => state.auth.cognitoUser);
  const isAuth =
    (cognitoSession && cognitoSession.isValid()) || (cognitoUser && Object.keys(cognitoUser).length);
  const player = useSelector((state) => state.player);
  const miniPlayerActive =
    isAuth && ((player.currentSong && player.type === "MINI") || player.type === "LIVE");

  if (isLoading) {
    return (
      <Wrapper>
        <Container>
          <Content isAuth={isAuth} miniPlayerActive={miniPlayerActive}>
            <Splash />
          </Content>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Content isAuth={isAuth} miniPlayerActive={miniPlayerActive}>
          <Switch>
            <PrivateRoute exact path={routes.home} component={Home} />
            <PrivateRoute exact path={routes.album} component={Album} />
            <PrivateRoute exact path={routes.library} component={Library} />
            <PrivateRoute exact path={routes.myList} component={MyList} />
            <PrivateRoute exact path={routes.profile} component={Profile} />
            <UnauthenticatedRoute path={routes.login} component={SignIn} />
            <UnauthenticatedRoute exact path={routes.redirect} component={Redirect} />
            <UnauthenticatedRoute exact path={routes.redirectFederatedSignIn} component={RedirectFederatedSignIn} />
          </Switch>
          <ScrollToTopControlller />
          {isAuth && (
            <>
              <Player />
              <TabBar />
            </>
          )}
        </Content>
      </Container>
    </Wrapper>
  );
}

export default Routes;
