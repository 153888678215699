export default {
  home: "/",
  library: "/biblioteca",
  myList: "/minha-lista",
  profile: "/meu-perfil",
  album: "/album",
  login: "/entrar",
  redirect: "/redirect/:email",
  redirectFederatedSignIn: "/redirect-federated-sign-in",
};
