import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Auth } from "aws-amplify";

import { Creators as AuthCreators } from "../../store/ducks/auth";
import { Creators as ProfileCreators } from "../../store/ducks/profile";

import { Block } from "../../styles/components";

import Button from "../../components/Button";
import Text from "../../components/Text";

import i from "../../assets";

import * as s from "./styles";
import { capitalizeAndUseFirstName } from "../../utils";

function Profile() {
  const dispatch = useDispatch();
  const { attributes, company } = useSelector((state) => state.auth);
  const { loading, plays = [], bookmarks = [] } = useSelector(
    (state) => state.profile
  );
  const { colors } = useSelector((state) => state.config);
  const [ companyDomain ] = window.location.host.split('.');

  function signOut() {
    if (companyDomain.includes('radioluizadigital') || companyDomain.includes('dev')) {
      Auth.signOut();
      dispatch(AuthCreators.setCognitoUser({}));
      window.location.href = 'https://idmagalu.luizalabs.com/auth/realms/CORP/protocol/openid-connect/logout?redirect_uri=https://radioluizadigital.shasta.com.br/';
    } else {
      Auth.signOut();
      dispatch(AuthCreators.setCognitoUser({}));
    }
  }

  useEffect(() => {
    dispatch(ProfileCreators.getMetricsRequest());
  }, []);

  return (
    <s.Container colors={colors}>
      <Block isColumn justifyContentSpaceAround maxHeight="55%" height="100%">
        <Block height="112px" width="100%" centered>
          <Block position="relative" height="100%" width="185px">
            <s.ImgWrapper colors={colors}>
              <s.Img colors={colors} src={attributes.profilePictureUrl} />
            </s.ImgWrapper>
            <s.ImgWrapper colors={colors}>
              <s.Img colors={colors} src={company.logoUrl} />
            </s.ImgWrapper>
          </Block>
        </Block>
        <Block isColumn centered>
          <s.Name marginBottom="5" color="white" fontSize="24" fontWeight={800}>
            {attributes?.name ? capitalizeAndUseFirstName(attributes.name) : 'Colaborador'}
          </s.Name>
          <Text color="white" fontSize="16" fontWeight={300}>
            {attributes.cognitoGroupName}
          </Text>
        </Block>
        <Block centered>
          <s.UserData colors={colors}>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <Text
                  color="white"
                  marginRight="10"
                  fontSize="34"
                  fontWeight={800}
                >
                  {plays?.length}
                </Text>
                <Block isColumn>
                  <Text color="white" fontSize="14" fontWeight={300}>
                    áudios
                  </Text>
                  <Text color="white" fontSize="14" fontWeight={300}>
                    escutados
                  </Text>
                </Block>
              </>
            )}
          </s.UserData>
          <s.UserData colors={colors}>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <Text
                  color="white"
                  marginRight="10"
                  fontSize="34"
                  fontWeight={800}
                >
                  {bookmarks?.length}
                </Text>
                <Block isColumn>
                  <Text color="white" fontSize="14" fontWeight={300}>
                    áudios
                  </Text>
                  <Text color="white" fontSize="14" fontWeight={300}>
                    na sua lista
                  </Text>
                </Block>
              </>
            )}
          </s.UserData>
        </Block>
      </Block>
      <Block
        maxHeight="45%"
        height="100%"
        alignItemsEnd
        justifyContentCenter
        paddingBottom="20"
        backgroundImage={i.imgMountainGrayscale}
      >
        <Button
          color="white"
          border="white"
          fontSize="18px"
          background="transparent"
          fontWeight="300"
          width="260px"
          onClick={signOut}
        >
          Sair
        </Button>
      </Block>
    </s.Container>
  );
}

export default Profile;
