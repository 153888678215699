import styled, { css } from "styled-components";
import { colors, device, config } from "./index";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: auto;

  padding-bottom: calc(
    ${({ isAuth }) => (isAuth ? config.tabBarHeight : "0px")} +
      ${({ miniPlayerActive }) =>
        miniPlayerActive ? config.miniPlayerHeight : "0px"}
  );

  @media ${device.fromMobileL} {
    max-width: ${device.size.mobileL};
  }
`;

export const Block = styled.div`
  display: flex;

  & > button {
    margin: 0 !important;
  }

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `}

  ${({ isColumn }) =>
    isColumn &&
    css`
      flex-direction: column;
    `}
  
  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${({ alignItemsCenter }) =>
    alignItemsCenter &&
    css`
      align-items: center;
    `}

  ${({ alignItemsEnd }) =>
    alignItemsEnd &&
    css`
      align-items: flex-end;
    `}

  ${({ justifyContentCenter }) =>
    justifyContentCenter &&
    css`
      justify-content: center;
    `}

  ${({ justifyContentSpaceBetween }) =>
    justifyContentSpaceBetween &&
    css`
      justify-content: space-between;
    `}

  ${({ justifyContentSpaceAround }) =>
    justifyContentSpaceAround &&
    css`
      justify-content: space-around;
    `}

    ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-bottom: ${marginBottom}px;
      `}

    ${({ paddingBottom }) =>
      paddingBottom &&
      css`
        padding-bottom: ${paddingBottom}px;
      `}

    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}

    ${({ backgroundImage }) =>
      backgroundImage &&
      css`
        background-image: url(${backgroundImage});
        background-position: 30% 75px;
        background-repeat: no-repeat;
        background-size: cover;
      `}
`;
