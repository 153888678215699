import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from './contexts/GlobalContext';

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

import "./config/reactotron";
import GlobalStyle from "./styles/global";
import Routes from "./routes";
import store from "./store";

const [ companyDomain ] = window.location.host.split('.');

const configureAmplifyByCompany = company => {
  const configuration = {
    'radioluizadigital': {
      domain: 'radioluizamobile-slmagazine.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://radioluizadigital.shasta.com.br/redirect-federated-sign-in',
      redirectSignOut: 'https://radioluizadigital.shasta.com.br/',
    },
    'library': {
      domain: 'radioluizamobile-prd.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://library.shasta.com.br/redirect-federated-sign-in',
      redirectSignOut: 'https://library.shasta.com.br/',
    },
    'dev': {
      domain: 'radioluizamobile-dev.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://dev.d6k556j2xwnhy.amplifyapp.com/redirect-federated-sign-in',
      redirectSignOut: 'https://dev.d6k556j2xwnhy.amplifyapp.com/',
    }
  }


  return configuration[company] ? configuration[company] : {}
}

const oauthDefault = {
  scope: ['openid', 'email', 'profile'],
  responseType: 'code'
}

Amplify.configure({ ...awsconfig, oauth: { ...configureAmplifyByCompany(companyDomain), ...oauthDefault } });

const App = () => (
  <Provider store={store}>
    <GlobalProvider>
      <BrowserRouter>
        <GlobalStyle />
        <Routes />
      </BrowserRouter>
    </GlobalProvider>
  </Provider>
);

export default App;
