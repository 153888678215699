import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Creators as PlaylistsActions } from "../../store/ducks/playlists";
import { Creators as PlaylistDetailsActions } from "../../store/ducks/playlistDetails";
import { apiGetAllPlaylistTypes } from "../../services/amplify/playlists";

import Layout from "../../components/Layout";
import Text from "../../components/Text";
import { KeepListening, Featured, StreamLive } from "../../components/Cards";
import RecommendedList from "../../components/RecommendedList";
import GenericList from "../../components/GenericList";

import { routes } from "../../routes";

import * as s from "./styles";

import { capitalizeAndUseFirstName, isMagaluUrl } from "../../utils";

const sortByDate = (a, b) => {
  const timestampA = new Date(a.createdAt).getTime();
  const timestampB = new Date(b.createdAt).getTime();

  return timestampA - timestampB;
};

function getMessageWelcome() {
  let d = new Date();
  let hour = d.getHours();
  if (hour < 5) {
    return "Boa noite, ";
  }
  if (hour < 12) {
    return "Bom dia, ";
  }
  if (hour < 18) {
    return "Boa tarde, ";
  }

  return "Boa noite, ";
}

function Welcome({ name }) {
  const { attributes } = useSelector((state) => state.auth);

  return (
    <>
      <Text color="white" fontSize="24" fontWeight={800}>
        {getMessageWelcome()}
      </Text>
      <s.Name
        textShadowColor="white"
        color="transparent"
        fontSize="40"
        fontWeight={800}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        letterSpacing="1px"
      >
        {attributes?.name ? capitalizeAndUseFirstName(attributes.name) : 'Colaborador'}
      </s.Name>
    </>
  );
}

function Home() {
  const dispatch = useDispatch();
  const { attributes } = useSelector((state) => state.auth);
  const { playlists, loading } = useSelector((state) => state.playlists);
  const { name } = attributes;
  const [featuredPlaylist, setFeaturedPlaylist] = useState();
  const [tagsPlaylist, setTagsPlaylist] = useState([]);
  const [playlistsByCategory, setPlaylistsByCategory] = useState();
  const [requestWasLoaded, setRequestWasLoaded] = useState(false);

  let history = useHistory();

  async function goToAlbum(p) {
    await dispatch(PlaylistDetailsActions.setPlaylistDetails(p));
    history.push(routes.album);
  }

  useEffect(() => {
    dispatch(PlaylistsActions.getPlaylistsRequest());
  }, []);

  useEffect(() => {
    if (playlists && playlists.length) {
      const playlistActiveForFeatured = playlists?.find(playlist => playlist?.active && playlist.playlistTypeID === '1');
      const fetchCategories = async () => {
        try {
          const categories = await apiGetAllPlaylistTypes();
          const categoriesWithPlaylists = categories.map(cat => {
            const filterPlaylistsByCategories = playlists.filter(playlist => playlist.playlistTypeID === cat.id);
            return {
              ...cat,
              playlists: filterPlaylistsByCategories
            }
          });

          const sortedCategories = categoriesWithPlaylists.sort(sortByDate);
          setPlaylistsByCategory(sortedCategories);
        } catch(error) {
          console.log(error);
        } finally {
          setRequestWasLoaded(true);
        }
      }

      fetchCategories();
      setFeaturedPlaylist(playlistActiveForFeatured || playlists[playlists.length - 1]);
    }

    const playlistByCategories = playlists?.reduce((acc, playlist, index) => {
      const numberOfAlbums = playlists?.filter(obj => obj?.tag === playlist?.tag).length;
      const alreadyHasName = acc?.find(ac => ac.name === playlist?.tag);


      if (alreadyHasName || !playlist.active) {
        return acc
      };

      const newObject =  {
        id: index + 1,
        name: playlist?.tag,
        numberOfAlbums
      }

      return [...acc, newObject];
    }, []);
    setTagsPlaylist(playlistByCategories);
  }, [playlists])

  const lastUnfinishedPlaylist = null;

  const keepListening = !!lastUnfinishedPlaylist;


  return (
    <Layout
      headerHeight={145}
      mountainVAlign={-60}
      headerComponent={<Welcome name={name} />}
    >
      <s.Container keepListening={keepListening}>
        {isMagaluUrl() ?
          <StreamLive /> : null
        }
        {keepListening ?
          <KeepListening playlist={lastUnfinishedPlaylist} /> : null
        }
        <Featured onClick={goToAlbum} playlist={featuredPlaylist} loading={loading} />
        {!requestWasLoaded ?
          <RecommendedList
            loading={requestWasLoaded}
            withSkeleton
          /> : null
        }
        {playlistsByCategory?.map(category => {
          if (category.id === '1' || category.playlistTypeDesc === 'Destaque') {
            return null
          }

          if (category.playlists && category.playlists.length) {
            return (<RecommendedList
              key={category.id}
              onClick={goToAlbum}
              items={category.playlists}
              loading={loading}
              withSkeleton
              title={category.playlistTypeDesc}
            />);
          }

          return null;
        })}
        <GenericList
          onClick={goToAlbum}
          inline
          title="Categorias"
          items={tagsPlaylist}
          loading={loading}
          withSkeleton
          headerRight={<s.Anchor to={routes.library}>Ver todos</s.Anchor>}
        />
      </s.Container>
    </Layout>
  );
}

export default Home;
