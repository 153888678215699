import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../styles";
import Text from "../../components/Text";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Anchor = styled(Link)`
  font-weight: 300;
  font-size: 16px;
  text-decoration: underline;
  color: ${colors.secondary};

  &:link {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`;

export const Name = styled(Text)`
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
