import { call, put, select } from "redux-saga/effects";

import { apiSetPlay } from "../../services/amplify/play";

import { Creators as PlayerActions } from "../ducks/player";
import { Creators as PlaylistDetailsActions } from "../ducks/playlistDetails";
import { Creators as PlaylistActions } from "../ducks/playlists";
import { Creators as ErrorActions } from "../ducks/error";

export function* setPlay({ payload, type }) {
  try {
    const { audioCompletedParam } = yield select((state) => state.config);

    const { currentSong, position = 0 } = payload;

    const { playID, id: fileid, totalTime, playlistID } = currentSong || {};
    const id = playID;
    const userid = yield select((state) => state.auth.attributes.id);
    const timePlay = parseInt(position);
    const completed = (position * 100) / totalTime > audioCompletedParam;

    const song = {
      ...currentSong,
      playID: id,
      timePlay,
    };

    if (currentSong && (type === 'Player/PLAYING' || type === 'player/LOAD')) {
      yield put(PlayerActions.setType('MINI'));
    }

    // SET LISTS (MY LIST, PLAYER, DETAILS)
    yield put(PlayerActions.updateSong({ song }));
    yield put(PlaylistDetailsActions.updateSong({ song }));
    yield put(PlaylistActions.setAudioInMyList(song));
    yield put(PlayerActions.setLive(false));

    const play = yield call(apiSetPlay, {
      id,
      userid,
      fileid,
      playlistid: playlistID,
      timePlay,
      completed,
    });

    if (id !== play.id) {
      const newSong = {
        ...currentSong,
        playID: play.id,
        completed: play.completed
      };

      // SET LISTS (MY LIST, PLAYER, DETAILS)
      yield put(PlayerActions.updateSong({ song: newSong }));
      yield put(PlaylistDetailsActions.updateSong({ song: newSong }));
      yield put(PlaylistActions.setAudioInMyList(newSong));
    }
  } catch (error) {
    console.log("error in saga setPlay >> ", error);
  }
}

export function* setLive({ isLive }) {
  try {
    if (isLive) {
      yield put(PlayerActions.pause());
    }
  } catch (error) {
    console.log('error in saga setLive >>', error);
  }
}
