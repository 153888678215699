import styled from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  padding-bottom: 30px;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const List = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    padding-top: 15px;
  }

  &.not-inline > :not(:last-child) {
    border-bottom: 1px solid ${colors.alto};
    padding-bottom: 15px;
  }

  &.inline > div {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }
`;

export const Item = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  margin-top: 25px;
  max-width: 190px;
  min-height: 100px;
  padding: 25px 0;
  text-align: center;
  width: 100%;

  a {
    text-decoration: none;
  }
`;
