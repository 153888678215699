import React from "react";
import Slider from "rc-slider";
import { useDispatch } from "react-redux";
import { Creators as PlayerActions } from "../../store/ducks/player";
import * as s from "./styles";

export default function ProgressSlider({
  railColor,
  trackColor,
  progress,
  song,
}) {
  const dispatch = useDispatch();

  return (
    <s.ProgressSlider>
      <Slider
        railStyle={{ background: railColor, borderRadius: 10 }}
        trackStyle={{ background: trackColor }}
        handleStyle={{ display: "none" }}
        max={1000}
        onChange={(value) =>
          song &&
          song.id &&
          dispatch(
            PlayerActions.handlePosition({
              percentage: value / 1000,
              currentSong: song,
            })
          )
        }
        onAfterChange={(value) =>
          song &&
          song.id &&
          dispatch(
            PlayerActions.setPosition({
              percentage: value / 1000,
              currentSong: song,
            })
          )
        }
        value={progress}
      />
    </s.ProgressSlider>
  );
}
