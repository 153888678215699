import React from "react";
import { useSelector } from "react-redux"

import * as s from "./styles";

function Text({ children, ...props }) {
  const { colors } = useSelector(state => state.config)
  return <s.Text colors={colors} {...props}>{children}</s.Text>;
}

export default Text;
