import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations2";
import { listPlays } from "../../graphql/queries2";

export async function apiSetPlay(input) {
  try {
    const operation = input.id ? "updatePlay" : "createPlay";
    const { data } = await API.graphql(
      graphqlOperation(mutations[operation], { input })
    );
    return data[operation];
  } catch (error) {
    console.log("error in set play >> ", error);
    return null;
  }
}

export async function apiGetPlaysOfUser(userid, playlistID) {
  try {
    const { data } = await API.graphql(
      graphqlOperation(listPlays, {
        filter: {
          userid: {
            eq: userid,
          },
          ...(playlistID && {
            playlistid: {
              eq: playlistID,
            },
          }),
        },
      })
    );
    return data.listPlays.items;
  } catch (error) {
    console.log("error in list play >> ", error);
    return null;
  }
}
