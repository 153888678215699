import { call, select, put } from "redux-saga/effects";

import { apiGetPlaysOfUser } from "../../services/amplify/play";
import { apiGetBookmarksOfUser } from "../../services/amplify/bookmark";

import { Creators as ProfileActions } from "../ducks/profile";
import { Creators as ErrorActions } from "../ducks/error";

export function* getMetrics() {
  console.log('profile saga >> getMetrics ========>');
  try {
    const { id } = yield select((state) => state.auth.attributes);
    console.log('profile saga >> getMetrics >> id ========>', id);
    console.log('==================');
    const plays = yield call(apiGetPlaysOfUser, id);
    console.log('profile saga >> getMetrics >> plays ========>', plays);
    console.log('==================');
    const bookmarks = yield call(apiGetBookmarksOfUser, id, null, true);
    console.log('profile saga >> getMetrics >> bookmarks ========>', bookmarks);
    console.log('==================');

    yield put(ProfileActions.getMetricsSuccess({ plays, bookmarks }));
  } catch (error) {
    console.log("error in saga getMetrics >> ", error);
  }
}
