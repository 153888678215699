import styled, { css } from "styled-components";
import { colors, device, animations, config } from "../../../styles";

export const Container = styled.div`
  background: ${colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  bottom: calc(${config.tabBarHeight} + 1px);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  animation: ${css`0.2s ${animations.slideInUpAnimation}`};
  padding: 20px;

  @media ${device.fromMobileL} {
    max-width: ${device.size.mobileL};
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-height: 650px;
  height: 100%;
  z-index: 1;
`;

export const Img = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 290px;
  max-height: 290px;
  margin: 20px 0;
`;

export const Btn = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  left: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 5px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  max-width: 290px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
`;

export const ProgressSlider = styled.div`
  width: 100%;
  max-width: 500px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  & > div {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${({ src }) => src});
  background-position: 40% -20%;
  background-repeat: no-repeat;
  background-size: 900px 400px;
  height: 30%;
  opacity: 0.15;
`;
