import React from "react";

import Text from "../Text";

import * as s from "./styles";

function Input({ label, type, ...props }) {
  return (
    <s.InputWrapper>
      <Text fontSize="14">{label}</Text>
      <s.Input type={type} {...props} />
    </s.InputWrapper>
  );
}

export default Input;
