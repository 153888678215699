import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Creators as AuthActions } from "../../store/ducks/auth";

import Splash from "../../components/Splash";

import { routes } from "../../routes";

function RedirectFederatedSignIn() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const { isLoading } = useSelector((state) => state.auth.signInState);

  async function setUp() {
    console.log('setUp >> RedirectFederatedSignIn Page');
    await dispatch(AuthActions.checkFederatedSignIn({ onSuccess: () => history.push(routes.home) }));
  }

  useEffect(() => {
    setUp();
  }, []);

  if (isLoading) {
    return <Splash />;
  }

  return <></>;
}

export default RedirectFederatedSignIn;
