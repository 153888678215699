export const Types = {
  GET_REQUEST: "playlists/GET_REQUEST",
  GET_SUCCESS: "playlists/GET_SUCCESS",
  GET_MY_LIST_REQUEST: "playlists/GET_MY_LIST_REQUEST",
  GET_MY_LIST_SUCCESS: "playlists/GET_MY_LIST_SUCCESS",
  SET_AUDIO_IN_MY_LIST: "playlists/SET_AUDIO_IN_MY_LIST",
};

const INITIAL_STATE = {
  playlists: [],
  myList: [],
  loading: false,
};

export default function playlists(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: !state.playlists.length };
    case Types.GET_SUCCESS:
      return { ...state, loading: false, playlists: action.payload };
    case Types.GET_MY_LIST_REQUEST:
      return { ...state, loading: !state.myList.length };
    case Types.GET_MY_LIST_SUCCESS:
      return { ...state, loading: false, myList: action.payload };
    case Types.SET_AUDIO_IN_MY_LIST:
      const audio = action.payload;
      const { bookmarked } = audio;

      const matchBookmark = (a) =>
        a.bookmarkID === audio.bookmarkID ||
        (a.id === audio.id && a.playlistID === audio.playlistID);

      const audioIndex = state.myList.findIndex((a) => matchBookmark(a));

      if (audioIndex > -1 && !bookmarked) {
        return {
          ...state,
          myList: state.myList.filter((a) => a.bookmarkID !== audio.bookmarkID),
        };
      } else if (audioIndex < 0 && bookmarked) {
        return {
          ...state,
          myList: [audio, ...state.myList],
        };
      } else if (audioIndex > -1 && bookmarked) {
        return {
          ...state,
          myList: state.myList.map((a) => (matchBookmark(a) ? audio : a)),
        };
      }

      return state;
    default:
      return state;
  }
}

export const Creators = {
  getPlaylistsRequest: () => ({ type: Types.GET_REQUEST }),
  getPlaylistsSuccess: (payload) => ({
    type: Types.GET_SUCCESS,
    payload,
  }),
  getMyListsRequest: () => ({ type: Types.GET_MY_LIST_REQUEST }),
  getMyListsSuccess: (payload) => ({
    type: Types.GET_MY_LIST_SUCCESS,
    payload,
  }),
  setAudioInMyList: (payload) => ({
    type: Types.SET_AUDIO_IN_MY_LIST,
    payload,
  }),
};
