import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, device, config } from "../../styles";

export const Container = styled.div`
  background: ${colors.white};
  height: ${config.tabBarHeight};
  box-shadow: 0px -3px 10px rgba(176, 173, 173, 0.29);
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  z-index: 2;

  @media ${device.fromMobileL} {
    max-width: ${device.size.mobileL};
    margin: 0 auto;
  }
`;

export const LinkItem = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg {
    margin-bottom: 5px;
  }

  &:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }

  &:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
`;
