import { call, select, put } from "redux-saga/effects";

import { apiGetPlaylistById } from "../../services/amplify/playlists";
import { apiGetPlaysOfUser } from "../../services/amplify/play";
import { apiGetBookmarksOfUser } from "../../services/amplify/bookmark";

import { Creators as PlaylistDetailActions } from "../ducks/playlistDetails";
import { Creators as ErrorActions } from "../ducks/error";

export function* getDetails({ payload }) {
  try {
    let audios = [];

    const { playlistID } = payload;
    const { audios: audiosList, playlistDetails } = yield select(
      (state) => state.playlistDetails
    );

    if (!audiosList.length || (playlistID && audiosList.some((a) => a.playlistID !== playlistID))) {
      const playlist = yield call(apiGetPlaylistById, playlistID);
      audios = playlist.files;
    } else {
      audios = audiosList;
    }

    const { id } = yield select((state) => state.auth.attributes);
    const plays = yield call(apiGetPlaysOfUser, id, playlistDetails.id);
    const bookmarks = yield call(apiGetBookmarksOfUser, id, playlistDetails.id);

    const updatedAudios = audios.map((a) => {
      const play = plays.find((p) => p.fileid === a.id) || {};
      const bookmark = bookmarks.find((b) => b.fileid === a.id) || {};

      if (!a.active) {
        return null;
      }

      return {
        ...a,
        playID: play.id,
        timePlay: play.timePlay,
        completed: play.completed,
        bookmarkID: bookmark.id,
        bookmarked: bookmark.active,
      };
    });

    const validUpdatedAudios = updatedAudios.filter(audios => audios);

    yield put(PlaylistDetailActions.getAudiosSuccess(validUpdatedAudios));
  } catch (error) {
    console.log("error in saga getDetails >> ", error);
  }
}
