import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Creators as PlaylistDetailsActions } from "../../store/ducks/playlistDetails";

import Text from "../../components/Text";
import AudioList from "../../components/AudioList";

import { ArrowBackSVG } from "../../assets";

import { routes } from "../../routes";

import * as s from "./styles";

function Album() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { playlistDetails, audios, loading } = useSelector(
    (state) => state.playlistDetails
  );
  const { coverUrl } = useSelector((state) => state.auth.company);

  const { id, newPlaylistId } = playlistDetails;

  const playlistID = newPlaylistId || id;

  if (!playlistID) {
    history.replace(routes.home);
  }

  useEffect(() => {
    dispatch(
      PlaylistDetailsActions.getAudiosRequest({
        playlistID,
        loading: newPlaylistId !== id,
      })
    );
  }, [playlistID]);

  return (
    <s.Container>
      <s.BackButton onClick={() => history.goBack()}>
        <ArrowBackSVG color="white" />
      </s.BackButton>
      <s.Banner src={playlistDetails.coverUrl || coverUrl} />
      <s.Header>
        <Text marginBottom="10" color="white" fontSize="20" fontWeight={800}>
          {playlistDetails.name}
        </Text>
        <Text marginBottom="10" color="white" fontSize="14" fontWeight={300}>
          {playlistDetails.desc}
        </Text>
      </s.Header>
      <s.Overlay>
        <AudioList
          loading={loading}
          playlistDetails={playlistDetails}
          audios={audios}
        />
      </s.Overlay>
    </s.Container>
  );
}

export default Album;
